import React, { useRef, useState, useEffect } from 'react';
import { Formik, Form, FormikHelpers as FormikActions, FormikErrors, FormikValues, useFormikContext, getIn } from 'formik';
import { Popover, Overlay, Button } from 'react-bootstrap'

import { handle_drf_form_error } from 'django-rest-react'
import { FormGroupBootstrap, ConfirmedButton, ErrorBlockGeneric } from '../common-components'
import { RuoliEvento } from '../globals';
import { post_invito_role } from '../api';


interface IProps {
  evento: number,
  ruolo: RuoliEvento,
}


const InvitaRuolo = (props: IProps) => {
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);

  const target = useRef(null);

  return (
    <>
      <Button
        className="btn btn-success" title="Invita"
        onClick={() => setShow(!show)}
        ref={target}>
        <i className="fa fa-plus" /> Invita
      </Button>
      <Overlay
        placement="top"
        show={show}
        target={target.current}
      >
        {({ ...old }: any) => {
          const newprops = {
            ...old,
            style: {
              ...old.style,
              maxHeight: "300px",
              maxWidth: "900px",
              overflow: "auto",
            }
          }

          return (
            <Popover id={`invita`} {...newprops}>
              <Popover.Title>
                <span className="mr-2">
                  <i className="fa fa-times text-muted"
                    onClick={() => setShow(false)} />
                </span>
                Invita a collaborare
              </Popover.Title>
              <Popover.Content>
                <Formik
                  initialValues={{ email: "", ruolo: undefined }}
                  onSubmit={(values, actions) => {
                    post_invito_role(props.evento, {
                      email: values.email,
                      role: props.ruolo,
                    })
                      .then(() => setShow(false))
                      .catch(err => handle_drf_form_error(err, actions, setError))
                      .finally(() => actions.setSubmitting(false))
                  }}
                >
                  {({ isSubmitting, setFieldValue, handleReset, values }) => {
                    return (
                      <div className="container-fluid">
                        <Form className="form needs-validation">
                          <FormGroupBootstrap name="email" type="email" help_text="Inserisci l'email di una persona registrata su questo sito che vuoi invitare a collaborare. Riceverà un link da seguire per accettare l'invito. Se inserisci un'email di qualcuno non registrato su questo sito, non riceverai alcun messaggio di errore." />
                          <div className="d-flex justify-content-end">
                            <ConfirmedButton
                              type="warning"
                              onSubmit={() => {
                                handleReset();
                                setFieldValue("scelta", 0)
                                setFieldValue("_file", null)
                              }}>
                              Reset
                            </ConfirmedButton>
                            <button type="submit" disabled={isSubmitting}
                              className="mx-1 btn btn-primary">Invia</button>
                          </div>
                          <ErrorBlockGeneric error={error} />
                        </Form>
                      </div>
                    );
                  }}
                </Formik>
              </Popover.Content>
            </Popover>
          );
        }}
      </Overlay>
    </>
  );

}

export default InvitaRuolo;
