import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";

import { SquadraDettagli, LoadingCircle, Tile } from '../components';
import { useEvento, useLogin, useSessione, useSquadra } from '../reducers';
import { login_url } from '../globals';


/**
 * Questo schermo ti permette di vedere le tue squadre iscritte ad una sessione, passata
 * via url parameter (pk).
 */
const SessioneTueSquadre = (props: {}) => {
  const login = useLogin()
  const sessioni = useSessione(props);
  const params = useParams<{ pk: string }>();
  const session_pk = parseInt(params.pk);
  const sessione = sessioni.find(ses => ses.id === session_pk)
  const squadre = useSquadra(session_pk);
  const eventi = useEvento(props);

  const evento = eventi.find(ev => ev.id === sessione?.evento);

  useEffect(() => {
    document.title = "Le tue squadre - GaS";
  }, [])

  if (!login.logged_in) {
    login_url()
  }

  const extra_text = evento?.tipologia == "o" ? "Vanno registrate su myolifis" : "Puoi registrarne qualcuna seguendo il link qui sotto.";
  const blocco = (squadre.length === 0) ?
    <p>Non hai ancora registrato squadre per questa sessione. {extra_text}</p>
    :
    <div className="d-flex justify-content-center flex-row flex-wrap">
      {squadre.map(squadra => <div key={squadra.id}><SquadraDettagli ufficiale={sessione?.ufficiale} squadra={squadra} /></div>)}
    </div>;

  let dettagli_sessione;
  if (sessione) {
    dettagli_sessione = (
      <>
        <h2>Sessione {sessione.nome}</h2>
        <p>La sessione {sessione.ufficiale || "non "} è ufficiale</p>
      </>
    );
  } else {
    dettagli_sessione = <LoadingCircle />;
  }
  return (
    <div className="container">
      <h1 className="page-header">Le tue squadre</h1>
      {dettagli_sessione}
      <Tile
        url={`/nuova-squadra/`}
        color="success"
        icon="fa-plus"
        text="Registra una squadra"
      />
      {blocco}
    </div>
  );
}

export default SessioneTueSquadre;
