import React, { Component } from 'react';
import { connect } from "react-redux";

import { SquadraTabella, SquadraInSessione, Problema } from '../api/types';
import { sortClassifica } from '../api';
import { AppState } from '../reducers';

import './TabellaPunteggiMinimal.scss'

interface ownProps {
  punteggi: number[],
  nomi: { [squadra: string]: string; },
  dettagli_problemi: Problema[],
  classifica: SquadraTabella[]
  squadre: SquadraInSessione[],
  scuole_id: number[],
  sessione_pk: number,
}

interface IState {
  sort_dir: 1 | -1,
  sort_field: "p" | "n",
}

interface RefreshProps {
  storia: AppState["punteggi"]
}

const mapStateToProps = (state: AppState) => {
  return { storia: state.punteggi }
}

type IProps = ownProps & RefreshProps

interface OldPosMap {
  [pk: string]: number
}

class TabellaPunteggiMinimal extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      sort_dir: 1,
      sort_field: "p",
    }
  }

  render() {
    let states_vec = this.props.storia[this.props.sessione_pk]?.states
    let old_pos: OldPosMap = {};
    if (states_vec?.length > 3) {
      const old_state = states_vec[3];
      old_pos = old_state.classifica.reduce(
        (dict, sq) => {
          dict[sq.id.toString()] = sq.posizione;
          return dict;
        }, {} as OldPosMap
      )
    }

    const classifica = sortClassifica(this.props.classifica, this.state.sort_dir, this.state.sort_field, 0);
    let problema_completo = [];
    for (let i = 0; i < this.props.dettagli_problemi.length; i++) {
      let cusu: any = Object.assign({}, this.props.dettagli_problemi[i]);
      cusu.punteggio = (this.props.punteggi) ? this.props.punteggi[i] : 0;
      problema_completo.push(cusu);
    }
    return (
      <>
        <div id="tabella-punteggi-minimale">
          <table className="table table-bordered table-striped
            table-hover text-nowrap table-responsive-lg">
            <thead className="thead-dark">
              <tr>
                <th key="1"
                  onClick={() => this.setState({
                    sort_field: "p",
                    sort_dir: (this.state.sort_dir * -1 as 1 | -1),
                  })}
                ><i className="fa fa-sort" /></th>
                <th key="2"
                  onClick={() => this.setState({
                    sort_field: "n",
                    sort_dir: (this.state.sort_dir * -1 as 1 | -1),
                  })}>
                  <i className="fa fa-sort" /> Squadra
                </th>
                <th key="0">
                  Punti
                </th>
              </tr>
            </thead>
            <tbody>
              {classifica.slice(0, 20).map(
                (squadra: SquadraTabella) => {
                  const extra = [1, 2, 3].includes(squadra.posizione) ? "font-weight-bold" : "";
                  const cambio = old_pos[squadra.id.toString()]
                  let cambio_icon = undefined;
                  if (cambio) {
                    if (squadra.posizione < cambio) {
                      // Posizione guadagnata
                      cambio_icon = <i className="fa fa-arrow-up text-success" />
                    } else if (squadra.posizione > cambio) {
                      // Posizione persa
                      cambio_icon = <i className="fa fa-arrow-down text-danger" />
                    }
                  }
                  return (
                    <tr key={squadra.id}>
                      <td className={extra}>
                        {cambio_icon}{squadra.posizione}
                      </td>
                      <td className={extra}>
                        {squadra.nome}
                      </td>
                      <td className={extra}>
                        {squadra.totale}
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps)(TabellaPunteggiMinimal);
