import { FormikHelpers as FormikActions, FormikErrors } from 'formik';
import { cloneDeep, isObject } from 'lodash'


interface FormErrorResponse {
  non_field_errors?: string,
  detail?: string,
  [key: string]: any
}


class ExtendableError extends Error {

  constructor(message: string) {
    super();
    this.message = message;
    this.stack = (new Error()).stack;
    this.name = this.constructor.name;
  }
}

export class APIError extends ExtendableError {
  err_detail: FormErrorResponse;
  constructor(message: FormErrorResponse) {
    super(message as unknown as string);
    this.err_detail = message;
  }
}


export class NetworkError extends APIError { }

// Per errori 5**
export class InternalServerError extends APIError { }
// Per errori 4**
export class ClientError extends APIError { }

// Specifico per 400
export class BadRequest extends ClientError { }

// Specifico 403
export class PermissionDenied extends ClientError { }

// Specifico 404
export class NotFound extends ClientError { }

// Specifico 429
export class TooManyRequests extends ClientError { }



export interface DRFErrorMsg {
  non_field_errors?: string,
  detail?: {
    [key: string]: string[]
  },
}


const allStrings = (vec: any[]) => vec.reduce((acc, val) => (typeof val === "string") && acc, true)

export const mergeDRFErrors = (err: any) => {
  let errcopy = cloneDeep(err);
  const recf = (obj: any) => Object.keys(obj).map(key => {
    if (Array.isArray(obj[key]) && allStrings(obj[key])) {
      obj[key] = obj[key].join(". ")
    }
    if (isObject(obj[key])) recf(obj[key])
  })
  recf(errcopy);
  return errcopy
}



export function handle_drf_form_error<FormValues>(
  error: APIError, actions: FormikActions<FormValues>,
  setError: (f: string) => void,
  extraErrorParsing = (x: DRFErrorMsg) => (x as FormikErrors<FormValues>),
  detailMsgHook: (val: any) => void = null
) {
  if (detailMsgHook === null) {
    detailMsgHook = val => actions.setErrors(val);
  }
  const msg = error.message as DRFErrorMsg;
  if (error instanceof BadRequest) {
    if (msg.non_field_errors) {
      setError(JSON.stringify(msg.non_field_errors));
    } else {
      detailMsgHook(extraErrorParsing(mergeDRFErrors(msg)))
    }
  } else if (error instanceof NetworkError) {
    setError("Errore di rete, non sono riuscito a contattare il server, riprova.")
  } else {
    setError(JSON.stringify(msg.detail ? msg.detail : msg));
  }
}
