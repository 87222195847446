import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeMathjax from 'rehype-mathjax';
import { parse_latex } from 'django-rest-react';


interface ownProps {
  source: string
}

const MdMath = (props: ownProps) => {

  const mdmath = parse_latex(props.source);

  return (
    <ReactMarkdown
      remarkPlugins={[remarkMath]} rehypePlugins={[rehypeMathjax]}
    >
      {mdmath}
    </ReactMarkdown>
  )
}

export default MdMath;
