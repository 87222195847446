import * as T from './auto-types';
import {
   get_paginated_json, post_page, put_page, patch_page, delete_page,
   get_single_json, plain_object,
} from 'django-rest-react';

export type DeepPartial<T> = T extends object ? {
  [P in keyof T]?: DeepPartial<T[P]>;
} : T;


export const delete_jolly = async (pk: number) => delete_page<T.JollyForm & { id: number }>(`/api/gas/jolly/${pk}/`)
export const delete_risposta = async (pk: number) => delete_page<T.RispostaForm & { id: number }>(`/api/gas/risposta/${pk}/`)
export const get_city_r_i = async () => get_paginated_json<T.CityRI & { id: number }>(`/api/ext-user/city/`)
export const get_country = async () => get_paginated_json<T.Country & { id: number }>(`/api/ext-user/country/`)
export interface FilterEvento {
inizio__gte?: string,
fine__lte?: string,
tipologia?: string,
tipologia__in?: string[],
tipologia_olifis?: string,
}
export const get_evento = async (filter: FilterEvento) => get_paginated_json<T.Evento & { id: number }>(`/api/gas/evento/`, filter as unknown as plain_object)
export const get_evento_detail = async (pk: number) => get_single_json<T.Evento & { id: number }>(`/api/gas/evento/${pk}/`)
export interface FilterJolly {
sessione?: number,
evento?: number,
}
export const get_jolly = async (filter: FilterJolly) => get_paginated_json<T.Jolly & { id: number }>(`/api/gas/jolly/`, filter as unknown as plain_object)
export const get_jolly_detail = async (pk: number) => get_single_json<T.Jolly & { id: number }>(`/api/gas/jolly/${pk}/`)
export const get_json_timestamp_detail_punteggi = async (pk: number) => get_single_json<T.JsonTimestamp & { id: number }>(`/api/gas/sessione/${pk}/punteggi/`)
export const get_messaggio_importante = async () => get_paginated_json<T.MessaggioImportante & { id: number }>(`/api/gas/messaggi-importanti/`)
export interface FilterProblema {
evento: number,
}
export const get_problema = async (filter: FilterProblema) => get_paginated_json<T.Problema & { id: number }>(`/api/gas/problema/`, filter as unknown as plain_object)
export const get_problema_detail = async (pk: number) => get_single_json<T.Problema & { id: number }>(`/api/gas/problema/${pk}/`)
export const get_region = async () => get_paginated_json<T.Region & { id: number }>(`/api/ext-user/region/`)
export interface FilterRisposta {
squadra?: number,
evento?: number,
sessione?: number,
problema?: number,
corretto?: boolean,
}
export const get_risposta = async (filter: FilterRisposta) => get_paginated_json<T.Risposta & { id: number }>(`/api/gas/risposta/`, filter as unknown as plain_object)
export const get_risposta_detail = async (pk: number) => get_single_json<T.Risposta & { id: number }>(`/api/gas/risposta/${pk}/`)
export interface FilterRispostaSenzaValore {
momento_consegna__gte?: string,
momento_consegna__lte?: string,
pk__in?: number[],
squadra?: number,
squadra__sessione?: number,
}
export const get_risposta_senza_valore = async (filter: FilterRispostaSenzaValore) => get_paginated_json<T.RispostaSenzaValore & { id: number }>(`/api/gas/risposta-all/`, filter as unknown as plain_object)
export const get_risposta_senza_valore_detail = async (pk: number) => get_single_json<T.RispostaSenzaValore & { id: number }>(`/api/gas/risposta-all/${pk}/`)
export interface FilterRispostaSenzaValore {
momento_consegna__gte?: string,
momento_consegna__lte?: string,
pk__in?: number[],
squadra?: number,
squadra__sessione?: number,
}
export const get_risposta_senza_valore_pk_risposte = async (filter: FilterRispostaSenzaValore) => get_paginated_json<T.RispostaSenzaValore & { id: number }>(`/api/gas/risposta-all/pk_risposte/`, filter as unknown as plain_object)
export const get_scuola_all = async () => get_paginated_json<T.ScuolaAll & { id: number }>(`/api/ext-user/scuola/`)
export const get_scuola_all_detail = async (pk: number) => get_single_json<T.ScuolaAll & { id: number }>(`/api/ext-user/scuola/${pk}/`)
export interface FilterSessione {
evento?: number,
evento__tipologia?: string,
}
export const get_sessione = async (filter: FilterSessione) => get_paginated_json<T.Sessione & { id: number }>(`/api/gas/sessione/`, filter as unknown as plain_object)
export const get_sessione_detail = async (pk: number) => get_single_json<T.Sessione & { id: number }>(`/api/gas/sessione/${pk}/`)
export const get_sessione_detail_emails = async (pk: number) => get_single_json<T.Sessione & { id: number }>(`/api/gas/sessione/${pk}/emails/`)
export const get_sessione_detail_myolifis_ids = async (pk: number) => get_single_json<T.Sessione & { id: number }>(`/api/gas/sessione/${pk}/myolifis_ids/`)
export const get_sessione_detail_persone = async (pk: number) => get_single_json<T.Sessione & { id: number }>(`/api/gas/sessione/${pk}/persone/`)
export const get_sessione_detail_plot_risposte = async (pk: number) => get_single_json<T.Sessione & { id: number }>(`/api/gas/sessione/${pk}/plot_risposte/`)
export const get_sessione_detail_punteggi_last = async (pk: number) => get_single_json<T.Sessione & { id: number }>(`/api/gas/sessione/${pk}/punteggi_last/`)
export const get_sessione_detail_squadre_myolifis = async (pk: number) => get_single_json<T.Sessione & { id: number }>(`/api/gas/sessione/${pk}/squadre_myolifis/`)
export const get_sessione_detail_statistiche_risposte = async (pk: number) => get_single_json<T.Sessione & { id: number }>(`/api/gas/sessione/${pk}/statistiche_risposte/`)
export const get_sessione_detail_stato = async (pk: number) => get_single_json<T.Sessione & { id: number }>(`/api/gas/sessione/${pk}/stato/`)
export interface FilterSquadra {
sessione?: number,
sessione__evento?: number,
}
export const get_squadra = async (filter: FilterSquadra) => get_paginated_json<T.Squadra & { id: number }>(`/api/gas/squadra/`, filter as unknown as plain_object)
export const get_squadra_detail = async (pk: number) => get_single_json<T.Squadra & { id: number }>(`/api/gas/squadra/${pk}/`)
export interface FilterSquadraInSessione {
sessione: number,
}
export const get_squadra_in_sessione_in_sessione = async (filter: FilterSquadraInSessione) => get_paginated_json<T.SquadraInSessione & { id: number }>(`/api/gas/squadra/in_sessione/`, filter as unknown as plain_object)
export const get_top_contributor_timestamp = async () => get_single_json<T.TopContributorTimestamp & { id: number }>(`/api/top-contributors/`)
export const get_user_responsabile = async () => get_paginated_json<T.UserResponsabile & { id: number }>(`/api/gas/user/`)
export const get_user_responsabile_detail = async (pk: number) => get_single_json<T.UserResponsabile & { id: number }>(`/api/gas/user/${pk}/`)
export const patch_evento_creation = async (pk: number, data: DeepPartial<T.EventoCreationForm>) => patch_page<T.EventoCreationForm & { id: number }>(`/api/gas/evento/${pk}/`, data as unknown as plain_object)
export const patch_file_pubblicazione_programmata_creation = async (pk: number, data: DeepPartial<T.FilePubblicazioneProgrammataCreationForm>) => patch_page<T.FilePubblicazioneProgrammataCreationForm & { id: number }>(`/api/gas/file-programmato/${pk}/`, data as unknown as plain_object)
export const patch_json_timestamp_delete_cache = async (pk: number, data: DeepPartial<T.JsonTimestampForm>) => patch_page<T.JsonTimestampForm & { id: number }>(`/api/gas/sessione/${pk}/delete_cache/`, data as unknown as plain_object)
export const patch_sessione_request_frontend_refresh = async (pk: number, data: DeepPartial<T.SessioneForm>) => patch_page<T.SessioneForm & { id: number }>(`/api/gas/sessione/${pk}/request_frontend_refresh/`, data as unknown as plain_object)
export const patch_sessione_request_refresh_frontend_delayed = async (pk: number, data: DeepPartial<T.SessioneForm>) => patch_page<T.SessioneForm & { id: number }>(`/api/gas/sessione/${pk}/request_refresh_frontend_delayed/`, data as unknown as plain_object)
export const patch_user_responsabile = async (pk: number, data: DeepPartial<T.UserResponsabileForm>) => patch_page<T.UserResponsabileForm & { id: number }>(`/api/gas/user/${pk}/`, data as unknown as plain_object)
export const post_evento_creation = async (data: T.EventoCreationForm) => post_page<T.EventoCreationForm & { id: number }>(`/api/gas/evento/`, data as unknown as plain_object)
export const post_evento_role_invite_detail_invita = async (pk: number, data: T.EventoRoleInviteForm) => post_page<T.EventoRoleInviteForm & { id: number }>(`/api/gas/evento/${pk}/invita/`, data as unknown as plain_object)
export const post_file_pubblicazione_programmata_creation = async (data: T.FilePubblicazioneProgrammataCreationForm) => post_page<T.FilePubblicazioneProgrammataCreationForm & { id: number }>(`/api/gas/file-programmato/`, data as unknown as plain_object)
export const post_jolly = async (data: T.JollyForm) => post_page<T.JollyForm & { id: number }>(`/api/gas/jolly/`, data as unknown as plain_object)
export const post_risposta = async (data: T.RispostaForm) => post_page<T.RispostaForm & { id: number }>(`/api/gas/risposta/`, data as unknown as plain_object)
export const post_squadra = async (data: T.SquadraForm) => post_page<T.SquadraForm & { id: number }>(`/api/gas/squadra/`, data as unknown as plain_object)
export const post_user_responsabile = async (data: T.UserResponsabileForm) => post_page<T.UserResponsabileForm & { id: number }>(`/api/gas/user/`, data as unknown as plain_object)
export const put_evento_creation = async (pk: number, data: T.EventoCreationForm) => put_page<T.EventoCreationForm & { id: number }>(`/api/gas/evento/${pk}/`, data as unknown as plain_object)
export const put_file_pubblicazione_programmata_creation = async (pk: number, data: T.FilePubblicazioneProgrammataCreationForm) => put_page<T.FilePubblicazioneProgrammataCreationForm & { id: number }>(`/api/gas/file-programmato/${pk}/`, data as unknown as plain_object)
export const put_user_responsabile = async (pk: number, data: T.UserResponsabileForm) => put_page<T.UserResponsabileForm & { id: number }>(`/api/gas/user/${pk}/`, data as unknown as plain_object)