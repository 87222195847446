import { connect } from 'react-redux';
import { Component } from 'react'

import {
  soft_refresh_cache, WithId, get_cached_item, get_cached_detail_item
} from './api_utils';
import { Esportone, StructuredEsportone, Filtro } from './redux-auto-solver';
import { AppState, reducers_map, store } from './imported_stuff';


type ownProps = any;

export interface FiltroItem<T extends WithId> {
  oggetto?: Esportone<T> | StructuredEsportone<T>,
  filtro?: (ownProps: ownProps) => Filtro,
  name: string,
  detail?: boolean
  pk?: (state: any, ownProps: ownProps) => number
  mapped_name?: string
  extra_filter?: (item: T, state: any, ownProps: ownProps) => boolean
}


export const connect_ensure_refresh:
  (rI: FiltroItem<any>[], mSTP?: any, mDTP?: any, mP?: any, o?: any) => (cl: typeof Component) => any =
  (refreshableItems, mapStateToProps, mapDispatchToProps, mergeProps, options) => (classe) => {
    const refresh_all = (ownProps: ownProps) => {
      for (const item of refreshableItems) {
        const oggetto = item.oggetto || reducers_map[item.name];
        const cache = oggetto.cache(store.getState());
        if (!cache.filter) {
          soft_refresh_cache(oggetto);
        } else if (item.filtro) {
          soft_refresh_cache(oggetto, item.filtro(ownProps));
        }
      }
    }
    const newMapStateToProps = (state: AppState, ownProps?: ownProps) => {
      let appo = Object();
      for (const item of refreshableItems) {
        const oggetto = item.oggetto || reducers_map[item.name];
        const cache = oggetto.cache(state);
        const mapped_name = item.mapped_name || item.name;
        if (!cache.filter) {
          if (item.detail && item.pk) {
            const pk = item.pk(state, ownProps);
            appo[mapped_name] = get_cached_detail_item(state, oggetto, pk);
          } else {
            if (item.extra_filter) {
              const filter_function = (item2: any) => item.extra_filter(item2, state, ownProps);
              appo[mapped_name] = get_cached_item(state, oggetto).filter(filter_function);
            } else {
              appo[mapped_name] = get_cached_item(state, oggetto);
            }
          }
        } else {
          if (item.filtro) {
            appo[mapped_name] = get_cached_item(
              state, oggetto, item.filtro(ownProps));
          } else {
            appo[mapped_name] = cache;
          }
        }
      }
      return Object.assign({}, {
        ...appo,
        ...mapStateToProps(state, ownProps)
      });
    }
    class appo_classe extends classe {
      constructor(props: any) {
        super(props)
        refresh_all(props);
      }
    }
    return connect(newMapStateToProps, mapDispatchToProps, mergeProps, options)(appo_classe);
  }
