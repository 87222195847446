import { Evento, Sessione, Responsabile } from '../api/types'
import { useLogin } from '../reducers'

export const isGasSuperuser = (login: ReturnType<typeof useLogin>) => {
  if (!initialResponsabileCheck(login)) return false;
  return login.user.is_superuser;
}


const initialResponsabileCheck = (login: ReturnType<typeof useLogin>) => {
  if (!login.logged_in || !login.user) return false
  const { responsabile } = login.user;
  if (!responsabile) return false
  if (!responsabile?.attivo) return false;
  return true;
}

export const canConsegnare = (login: ReturnType<typeof useLogin>, evento: Evento, sessione?: Sessione) => {
  if (!initialResponsabileCheck(login)) return false;
  if (isGasSuperuser(login)) return true;
  const { responsabile } = login.user;

  if (evento?.tipologia === "o") {
    if (!sessione) return true
    return (sessione.ufficiale && responsabile.puo_iscriversi_gare_ufficiali) || (!sessione.ufficiale);
  } else {
    return evento?.amministratori.includes(responsabile.id) || evento?.inseritori.includes(responsabile.id)
  }
}


export const canCancellareRisposte = (login: ReturnType<typeof useLogin>, evento: Evento) => {
  if (!initialResponsabileCheck(login)) return false;
  if (isGasSuperuser(login)) return true;

  const { responsabile } = login.user;
  return evento?.inseritori.includes(responsabile.id);
}


export const isEventoAdmin = (login: ReturnType<typeof useLogin>, evento: Evento) => {
  if (!initialResponsabileCheck(login)) return false;
  if (isGasSuperuser(login)) return true;

  const { responsabile } = login.user;
  return evento?.amministratori.includes(responsabile.id);
}


export const canIscrivereSquadre = (login: ReturnType<typeof useLogin>, evento: Evento) => {
  if (!initialResponsabileCheck(login)) return false;
  if (isGasSuperuser(login)) return true;

  if (evento.tipologia === "pub") return true;
  const { responsabile } = login.user;
  return evento?.amministratori.includes(responsabile.id);
}
