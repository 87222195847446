import React from 'react'
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/types';
import { sentry_config } from '../globals';

import {
  APIError, InternalServerError, ClientError, BadRequest,
  PermissionDenied, NotFound
} from 'django-rest-react';
import { LoginStateProps } from '../utils';


export type GetUsername = (l: LoginStateProps["login"]) => string


export const parseErrors = (err: Error, login: LoginStateProps["login"], get_username: GetUsername) => {
  let block; let dettaglio;
  let error_level = Severity.Error;
  if (err instanceof APIError) {
    if (err instanceof PermissionDenied) {
      block = "403: Permesso negato";
      error_level = Severity.Warning;
    } else if (err instanceof InternalServerError) {
      block = "Internal Server error";
    } else if (err instanceof BadRequest ||
      err instanceof ClientError) {
      block = "400: Bad request";
    } else if (err instanceof NotFound) {
      block = "404: Oggetto non trovato";
    } else {
      block = "Errore generico";
    }
    dettaglio = <p>Errore</p>;
    if (err.err_detail?.detail) {
      dettaglio = <p>{err.err_detail.detail}</p>;
    } else if (err.message) {
      dettaglio = <p>{JSON.stringify(err.message)}</p>;
    }
  } else {
    block = "L'applicazione è crashata";
    dettaglio = <pre>{err.stack}</pre>
    console.trace()
  }
  if (sentry_config.use_sentry) {
    const username = login.logged_in ?
      get_username(login) :
      "Anonymous";
    Sentry.configureScope((scope) => {
      scope.setUser({
        "username": username
      });
      scope.setLevel(error_level);
    });
    Sentry.captureException(err);
  }
  return {
    block: block,
    dettaglio: dettaglio,
  }
}
