import React, { Component } from 'react';
import { connect } from "react-redux";

import Plot from 'react-plotly.js';
import { AppState } from '../reducers';
import { PunteggiSessione, Evento } from '../api/types';
import LoadingCircle from '../components/LoadingCircle'
import { rgb_string_from_name } from '../utils'


interface IProps {
  pk: number,
  punteggi: PunteggiSessione,
  evento: Evento,
}


const mapStateToProps = (state: AppState) => {
  return { punteggi: state.punteggi }
}


class IstogrammaPunteggi extends Component<IProps> {

  render() {
    if (this.props.evento) {
      const adesso = new Date();
      const inizio = new Date(this.props.evento.inizio);
      if (inizio > adesso) {
        return <p>L'evento non è ancora iniziato.</p>;
      }
    }
    const pp = this.props.punteggi;
    if (Object.entries(pp).length === 0 && pp.constructor === Object) {
      return (
        <LoadingCircle />
      );
    }
    let p;
    try {
      p = pp[this.props.pk].states[0].classifica.map(val => val.totale);
    } catch (err) {
      return (
        <LoadingCircle />
      );
    }

    return (
      <Plot
        data={[{
          x: p,
          type: "histogram",
        }]}
        layout={{
          width: 1600, height: 600, title: "Punteggio",
          margin: { l: 200 },
          xaxis: { title: "Punteggio" },
          yaxis: { title: "Occorrenze" },
        }}
      />
    )
  }
}


const IstogrammaPunteggi_ = connect(mapStateToProps)(IstogrammaPunteggi);
export default IstogrammaPunteggi_;
