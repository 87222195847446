import { pad } from './utils';
import { cloneDeep } from 'lodash';

export const DEFAULT_LOCALE = "it-IT";
export const default_date_options: Intl.DateTimeFormatOptions = {
  weekday: 'long', year: 'numeric', month: 'long',
  day: 'numeric', hour: 'numeric', minute: 'numeric',
};

const DEFAULT_OPTIONS = {
  locale: DEFAULT_LOCALE,
  date_options: default_date_options,
}


export function set_options(locale = DEFAULT_LOCALE, options = default_date_options) {
  DEFAULT_OPTIONS.locale = locale;
  DEFAULT_OPTIONS.date_options = cloneDeep(options);
}

/**
 * Restituisce true se le due date hanno lo stesso giorno.
 */
export function same_day(date1: Date, date2: Date): boolean {
  return ((date1.getDate() == date2.getDate()) &&
    (date1.getMonth() == date2.getMonth()) &&
    (date1.getFullYear() == date2.getFullYear()));
}


export function orario_paddato(date: Date): string {
  return pad(date.getHours() as unknown as string, 2) +
    ":" + pad(date.getMinutes() as unknown as string, 2);
}


export function base_nice_date(date: Date, locale = DEFAULT_OPTIONS.locale, options = DEFAULT_OPTIONS.date_options): string {
  let data_bella;
  const adesso = new Date();
  if (same_day(adesso, date)) {
    data_bella = orario_paddato(date);
  } else {
    data_bella = date.toLocaleDateString(locale, options);
  }
  return data_bella;

}

export function nice_date(date: Date | string): string {
  const appo = (typeof date === "string") ? new Date(date) : date;
  return base_nice_date(appo);
}

/* Nel formato ISO8601 ci sono dei '+', ma + vuole dire spazio nelle richieste
 * HTTP, per cui lo urlencodiamo con %2B
 */
export function urlencode_iso8601_date(date: Date): string {
  return date.toISOString().replace(/\+/g, "%2B");
}


export function nice_time_interval(seconds: number): string {
  let tempo: string;
  if (seconds < 5) {
    tempo = "Adesso";
  } else if (seconds < 60) {
    tempo = seconds + " secondi fa";
  } else {
    const minuti = Math.floor(seconds / 60)
    const min = (minuti < 2) ? "o" : "i";
    tempo = `${minuti} minut${min} fa`;
  }
  return tempo
}
