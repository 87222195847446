import React, { useState, ReactElement } from 'react';
import humanizeDuration from 'humanize-duration';
import { Link } from 'react-router-dom'
import Accordion from 'react-bootstrap/Accordion'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle'

import { TIPOLOGIE, DISCORD_INVITE_LINK, canConsegnare, isEventoAdmin } from '../globals';
import { Evento, Sessione, Responsabile } from '../api/types';
import { get_squadre_sessione } from '../api'
import { FilePubblicazioneProgrammataComponent, Tile, MdMath } from './';
import {
  displayDisplayable,
  nice_date_discorsiva, nice_date_estesa
} from '../utils';
import { useLogin, useSessione } from '../reducers';

import './EventoDettagli.css'


interface ownProps {
  evento: Evento
  verbose: boolean,
}


interface DetailProps {
  evento: Evento,
  sessioni: Sessione[],
}

interface SessioneProps extends DetailProps {
  sessione: Sessione
}

type Toggle = (a: { children: any, eventKey: string }) => ReactElement

const SessioneDetails = (props: SessioneProps) => {
  const [squadre_, setSquadre] = useState([]);
  const { sessione, evento } = props
  const login = useLogin();

  const RefreshSquadreToggle: Toggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionToggle(eventKey, () => {
      if (squadre_.length == 0) {
        get_squadre_sessione(props.sessione.id).then(setSquadre)
      }
    });

    return (
      <button
        type="button" className="btn btn-info" onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  const squadre = squadre_.map(item => {
    const scuola = item.scuola;
    const tipo = displayDisplayable(scuola.tipologia, TIPOLOGIE);
    const indirizzo = scuola.indirizzo ? scuola.indirizzo.citta.name : "";
    return (
      <tr key={item.id}>
        <td>{item.nome}</td>
        <td>{tipo} {scuola.nome}</td>
        <td>{indirizzo}</td>
        <td><Link className="btn btn-success" to={`/sessione/${sessione.id}/punteggi/scuola/${scuola.id}/`}>Segui</Link></td>
      </tr>
    );
  })

  let extra_links;
  const mostra = canConsegnare(login, evento, sessione);
  if (mostra) {
    extra_links = (
      <>
        <div className="pretty-medium-tile">
          <Tile
            url={`/sessione/${sessione.id}/consegna-risposte/`}
            color="secondary" text="Consegna risposte"
            icon="fa-pencil-alt"
          />
        </div>
        <div className="pretty-medium-tile">
          <Tile
            url={`/sessione/${sessione.id}/le-tue-squadre/`}
            color="warning" text="Le tue squadre"
            icon="fa-address-book"
          />
        </div>
      </>
    );
  }
  return (
    <Accordion>
      <div className="card my-3" key={sessione.id}>
        <div className="card-header">
          <h5>
            <RefreshSquadreToggle eventKey="0">
              <i className="fa fa-solid fa-arrows-alt-v"></i> {sessione.nome}
            </RefreshSquadreToggle>
            <span className="ml-3">Azioni disponibili</span>
          </h5>
          <div className="d-flex flex-row flex-wrap justify-content-center">
            <div className="pretty-medium-tile">
              <Tile
                url={`/sessione/${sessione.id}/punteggi/`}
                color="primary" text="Punteggi"
                icon="fa-list-ol"
              />
            </div>
            <div className="pretty-medium-tile">
              <Tile
                url={DISCORD_INVITE_LINK}
                color="discord" text="Chat"
                icon="fab fa-discord"
                absolute
              />
            </div>
            {extra_links}
          </div>

        </div>
        <Accordion.Collapse eventKey="0">
          <div className="card-body">
            <h4>{sessione.nome}</h4>
            <h5>Lista di squadre iscritte</h5>
            <table className="table table-responsive">
              <thead>
                <tr>
                  <th>Nome Squadra</th>
                  <th>Scuola</th>
                  <th>Città</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {squadre}
              </tbody>
            </table>
          </div>
        </Accordion.Collapse>
      </div>
    </Accordion>
  )
}


const EventoDettagli = (props: ownProps) => {
  const sessioni = useSessione(props);
  const login = useLogin();
  // verbose = true forza il display completo, utile ad esempio dentro il tab Eventi
  const { verbose, evento } = props;
  let real_verbose = verbose;

  const sessioni_filtrate = sessioni.filter(item => item.evento == evento.id);
  let adesso = new Date();
  let inizio = new Date(Date.parse(evento.inizio));
  let fine = new Date(Date.parse(evento.fine));
  let testo_inizio = "";
  const duration = humanizeDuration(fine.getTime() - inizio.getTime(), { language: "it" });
  if (adesso < inizio) {
    testo_inizio = `L'evento inzierà ${nice_date_discorsiva(inizio)}. La durata dell'evento è di ${duration}.`;
  } else if (adesso > fine) {
    testo_inizio = `L'evento si è concluso ${nice_date_discorsiva(fine)}. La durata dell'evento è stata di ${duration}.`;
  } else {
    testo_inizio = `L'evento è in corso! La durata dell'evento è di ${duration}.`;
    real_verbose = true;
  }
  return (
    <div className="jumbotron mb-3 event-container">
      <h2><MdMath source={props.evento.titolo} /></h2>
      <p>{testo_inizio}</p>
      <p>Le iscrizioni sono aperte nel seguente periodo: {nice_date_estesa(evento.inizio_iscrizioni)} - {nice_date_estesa(evento.fine_iscrizioni)}.</p>
      <p>Scadenza scelta del jolly: {nice_date_estesa(evento.fine_consegna_jolly)}.</p>
      <div className="container-fluid">
        <div className="d-flex flex-row flex-wrap justify-content-center">
          <div className="pretty-medium-tile">
            <FilePubblicazioneProgrammataComponent
              file={evento.file_problemi} didascalia="Problemi"
            />
          </div>
          <div className="pretty-medium-tile">
            <FilePubblicazioneProgrammataComponent
              file={evento.file_soluzioni} didascalia="Soluzioni"
            />
          </div>
          {evento.youtube_link &&
            <div className="pretty-medium-tile">
              <Tile
                url={evento.youtube_link}
                color="red"
                text="Telecronaca"
                icon="fab fa-youtube"
                absolute
              />
            </div>
          }
          {evento.pagina_dedicata &&
            <div className="pretty-medium-tile">
              <Tile
                url={evento.pagina_dedicata}
                color="blue"
                text="Pagina dedicata"
                icon="fa fa-image"
                absolute
              />
            </div>

          }
          {canConsegnare(login, evento) &&
            <div className="pretty-medium-tile">
              <Tile
                url={`/evento/${evento.id}/consegna-risposte/`}
                color="secondary" text="Consegna risposte"
                icon="fa-pencil-alt"
              />
            </div>
          }
          {isEventoAdmin(login, evento) &&
            <div className="pretty-medium-tile">
              <Tile
                url={`/modifica-evento/${evento.id}/`}
                color="pomegranate" text="Modifica l'evento"
                icon="fa-pencil-alt"
              />
            </div>
          }
        </div>
      </div>
      {real_verbose &&
        <>
          <h3>Sessioni per questo evento:</h3>
          <div>
            {sessioni_filtrate.map(sessione =>
              <SessioneDetails
                key={sessione.id} evento={evento} sessioni={sessioni_filtrate}
                sessione={sessione}
              />)}
          </div>
        </>
      }
    </div>
  );
}


export default EventoDettagli;
