import React from 'react'
import { useFormikContext } from 'formik'
import { cloneDeep } from 'lodash'

import { FormGroupBootstrap } from '../common-components'
import { MdMath } from '../components'
import { DECIMAL_PLACES } from '../globals'
import type { post_evento } from '../api';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'


type FormValues = Parameters<typeof post_evento>[0];

const prefix = "problemi";
const build_name = (idx: number, name: string) => `${prefix}[${idx}].${name}`


const MultipleSoluzioniForm = () => {
  const formik = useFormikContext();
  const { setFieldValue } = formik;
  const values = formik.values as FormValues;
  const count = !!values.problemi ? values.problemi.length : 0
  const onDragEnd = (result: DropResult) => {
    const newarr = cloneDeep(values.problemi);
    newarr.splice(result.destination.index, 0, newarr.splice(result.source.index, 1)[0]);
    for (let i = 0; i < newarr.length; i++) {
      newarr[i].nome = `P${i + 1}`;
    }
    setFieldValue("problemi", newarr);
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="problemi_evento">
        {(provided, snapshot) => {

          return (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {count >= 0 &&
                Array.from(Array(count).keys()).map(idx => {
                  const prob = values.problemi[idx];
                  const sol = parseFloat(prob.soluzione);
                  const iv = parseFloat(prob.intervallo_validita);
                  const lower = sol >= 0 ? sol / (1 + iv) : sol * (1 + iv);
                  const upper = sol >= 0 ? sol * (1 + iv) : sol / (1 - iv);
                  const l = lower.toFixed(Math.round(-Math.log10(DECIMAL_PLACES)));
                  const u = upper.toFixed(Math.round(-Math.log10(DECIMAL_PLACES)));
                  const ex = prob.soluzione_exp;
                  const src = `${prob.nome}: $\\num{${l}e${ex}}$ ${prob.udm_soluzione} $\\leq R \\leq \\num{${u}e${ex}}$ ${prob.udm_soluzione}`
                  return (
                    <Draggable key={idx} draggableId={idx.toString()} index={idx}>
                      {(provided, snapshot) => {

                        return (
                          <div className="card my-2" key={idx}
                            ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                          >
                            <div className="card-body">
                              <button type="button" onClick={() => {
                                const arrcopy = cloneDeep(values.problemi);
                                arrcopy.splice(idx, 1)
                                for (let i = 0; i < arrcopy.length; i++) {
                                  arrcopy[i].nome = `P${i + 1}`;
                                }
                                setFieldValue(prefix, arrcopy)
                              }}
                                className="ml-auto btn btn-danger my-2"
                              >
                                <i className="fa fa-times" />
                              </button>
                              <FormGroupBootstrap
                                name={build_name(idx, "nome")}
                                help_text="Numero del problema"
                                type="text"
                                readOnly
                              />
                              <FormGroupBootstrap
                                name={build_name(idx, "soluzione")} displayName="Valore"
                                type="number" step={DECIMAL_PLACES}
                                help_text={"Soluzione numerica. L'unità di misura è nel campo sotto. "
                                  + "Indica la risposta in notazione scientifica con un alto "
                                  + "numero di cifre significative. C'è il campo sotto per l'esponente."}
                              />
                              <FormGroupBootstrap
                                name={build_name(idx, "soluzione_exp")} displayName="Esponente"
                                type="number" step={1}
                              />

                              <FormGroupBootstrap
                                name={build_name(idx, "intervallo_validita")} displayName="Precisione"
                                type="number" step={DECIMAL_PLACES}
                                help_text={"La precisione con cui viene controllata la risposta. (1/(1 + p) <= r/a <= 1 + p)"}
                              />
                              <FormGroupBootstrap
                                name={build_name(idx, "udm_soluzione")} displayName="Unità di misura"
                                help_text={"Le unitá di misura del risultato. Dare con MathJax e.g. $\\si{m/s}$." +
                                  "Usare \\si{} se adimensionale. Cercare di dare una risposta in unità più fondamentali " +
                                  "possibili, per esempio m e non km, per non 'suggerire' la risposta"}
                                type="text"
                                extra_append={
                                  <div className="input-group-append">
                                    <div className="input-group-text">
                                      <MdMath source={values.problemi[idx].udm_soluzione || ""} />
                                    </div>
                                  </div>
                                }
                              />

                              <div className="d-flex justify-content-center">
                                <MdMath source={src} />
                              </div>
                            </div>
                          </div>
                        )
                      }}
                    </Draggable>
                  )
                }
                )}
              {provided.placeholder}
              <div className="d-flex justify-content-end py-2">
                <div>
                  <button type="button" onClick={() => {
                    setFieldValue(`${prefix}[${count}]`, {
                      nome: `P${count + 1}`,
                      udm_soluzione: "$\\si{}$",
                      soluzione: 0.0,
                      soluzione_exp: 0,
                      intervallo_validita: 0.005,
                    })
                  }}
                    className="mr-auto btn btn-success">Aggiungi una domanda
                  </button>
                </div>
              </div>
            </div>
          )
        }}
      </Droppable>
    </DragDropContext>
  )
}


export default MultipleSoluzioniForm
