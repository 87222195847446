import React from 'react';
import { useRouteError } from 'react-router-dom';

import { useLogin } from '../reducers'
import { GetUsername, parseErrors } from './parse_errors'


interface IProps {
  get_username: GetUsername
}

const APIErrorCatcherFunctional = (props: IProps) => {
  const login = useLogin();
  const error = useRouteError() as Error;
  const { block, dettaglio } = parseErrors(error, login, props.get_username);
  return (
    <div className="container">
      <h1 className="page-header">{block}</h1>
      <p>È molto probabile ci sia già arrivata una email riguardo questo incidente, ma puoi comunque segnalarcelo.
        Prova a ricaricare la pagina per risolvere il problema. Se persiste, contattaci. Ecco i dettagli dell'errore:</p>
      {dettaglio}
    </div>
  );

}

export default APIErrorCatcherFunctional
