import React, { Component } from 'react';
import { connect } from 'react-redux';

import { AppState } from '../reducers';
import { ToastWithTime } from './';


interface StateProps {
  toasts: AppState["toasts"]["list"]
}

const mapStateToProps: (state: AppState) => StateProps =
  (state) => {
    return {
      toasts: state.toasts.list
    };
  }


type IProps = StateProps;

class ToastFeed_ extends Component<IProps> {

  render() {
    return (
      <div
        aria-live="polite" aria-atomic="true"
        style={{ position: 'fixed', top: 55, right: 0, zIndex: 100000, }}
        className="mt-2 mr-2 ml-2"
      >
        <div style={{ position: 'relative', top: 0, right: 0 }} >
          {
            this.props.toasts.map(item => {
              return <ToastWithTime
                key={item.id}
                {...item}
              />;
            })
          }
        </div>
      </div >
    );
  }
}

const ToastFeed = connect(mapStateToProps)(ToastFeed_);
export default ToastFeed;
