import React, { useState } from 'react'
import { Formik, Form, FormikHelpers as FormikActions } from 'formik';
import { useLocation, useMatch } from 'react-router-dom'
import { connect } from 'react-redux'

import { FormGroupBootstrap, ErrorBlockGeneric } from '../common-components'
import { get_discord_token } from '../api'
import { handle_drf_form_error } from 'django-rest-react'
import { mapStateToProps, LoginStateProps } from '../utils'
import { useLogin } from '../reducers';
import { login_url } from '../globals';


export const DiscordToken = () => {
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null)
  const location = useLocation()
  const login = useLogin();

  const user = new URLSearchParams(location.search).get("user") || ""

  if (!login.logged_in) {
    login_url()
  }


  return (
    <div className="container">
      <h1 className="page-header">Discord Token</h1>
      {!token &&
        <Formik
          onSubmit={(values, actions) => {
            get_discord_token(values.user)
              .then(ans => setToken(ans.token))
              .catch(err => handle_drf_form_error(err, actions, setError))
              .finally(() => actions.setSubmitting(false))
          }}
          initialValues={{ user: user }}
        >
          {({ isSubmitting }) => {
            return (
              <Form className="needs-validation">
                <FormGroupBootstrap
                  name="user" type="text"
                  help_text="Il tuo username di discord, senza il cancelletto e i numeri dopo."
                />
                <ErrorBlockGeneric error={error} />
                <div className="d-flex justify-content-end">
                  <button className="btn btn-success" disabled={isSubmitting}>
                    Invia
                  </button>
                </div>
              </Form>
            )
          }}
        </Formik>}
      {token &&
        <div>
          <br />
          <p>Il tuo token:</p>
          <pre>{token}</pre>
          <div className="d-flex justify-content-between">
            <button className="btn btn-warning" type="button"
              onClick={() => setToken(null)}
            >
              Reset
            </button>
            <button className="btn btn-success" type="button"
              onClick={() => navigator.clipboard.writeText(token)}
            >
              Copia
            </button>
          </div>
        </div>
      }
    </div>
  )
}


const DiscordToken_ = connect(mapStateToProps)(DiscordToken)
export default DiscordToken_
