import React from 'react'
import { DeleteCache, RequestRefresh } from '../components';


const AdminActions = (props: {}) => {


  return (
    <div className="container mb-4">
      <DeleteCache />
      <RequestRefresh />
    </div>
  )
}


export default AdminActions;
