import {
  ADD_LOADING, DEL_LOADING, loadingAction, RESET_TENTATIVO, NUOVO_TENTATIVO,
  TentativoAction,
} from './actions';


export interface TentativoState {
  [a: number]: number
}

export function tentativoReducer(state: TentativoState = {}, action: TentativoAction) {
  if (action.type == RESET_TENTATIVO) {
    let copy = Object.assign({}, state);
    copy[action.index] = 0;
    return copy;
  } else if (action.type == NUOVO_TENTATIVO) {
    let copy = Object.assign({}, state);
    if (copy) {
      copy[action.index] =
        copy[action.index] ?
          copy[action.index] + 1 :
          1;
    } else {
      copy = {
        [action.index]: 1
      }
    }
    return copy;
  } else {
    return state;
  }
}


export interface LoadingState {
  loading: number
}

const loading_state = {
  loading: 0
}

export function loadingReducer(state: LoadingState = loading_state, action: loadingAction) {
  if (action.type == ADD_LOADING) {
    return Object.assign({}, {
      loading: state.loading + 1
    });
  } else if (action.type == DEL_LOADING) {
    return Object.assign({}, {
      loading: state.loading - 1
    });
  } else {
    return state;
  }
}
