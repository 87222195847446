import React, { Component, CSSProperties } from 'react';

import ClipLoader from 'react-spinners/ClipLoader';

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
}


const LoadingCircle = () => {
  return <ClipLoader
    cssOverride={override}
    color={'#42f456'}
    loading={true}
  />
}

export default LoadingCircle
