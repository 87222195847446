import React from 'react'
import { Link } from 'react-router-dom'

import { UserWhoami, Squadra, Evento, Sessione } from '../api/types'
import { DataTable } from '../components'
import { badgePermessi, displayScuola, displaySquadra } from '../utils'
import { useLogin, useScuola } from '../reducers'


const extra_style = {
  width: '33rem'
}


interface ownProps {
  user: UserWhoami
  squadre: Squadra[]
  sessioni: Sessione[],
  eventi: Evento[],
}

const Responsabile = (props: ownProps) => {
  const login = useLogin();
  const scuole = useScuola(props);

  const { user } = props
  const resp = user.responsabile;
  if (!resp) {
    return (
      <div className="card my-2 mx-1 shadow d-flex flex-column" style={extra_style}>
        <div className="card-body">
          <h3 className="card-title">{user.username}</h3>
          <p>Questo utente non ha associato un responsabile. Se vedi questo messaggio e non sei un amministratore, segnalacelo.</p>
        </div>
      </div>
    )
  }
  let testo;
  switch (resp.ruolo) {
    case "sp": testo = "Sei il responsabile di riferimento per le scuole indicate qui sotto. " +
      "Puoi creare dei responsabili secondari a cui affidare delle squadre" +
      " e puoi tu stesso sottomettere risposte per le squadre delle scuole di cui sei referente."
      break;
      ;
    case "ss": testo = "Sei un responsabile di squadra. Ti sono state assegnate alcune squadre dal responsabile principale" +
      " e non puoi iscriverne altre, puoi solo consegnare risposte per quelle che ti sono affidate.";
      break;
  }
  const princ_self = login.user.responsabile.ruolo == "sp";
  const princ_trgt = user.responsabile.ruolo == "sp";
  const squadre_widg = princ_trgt ?
    <span>Sei un responsabile principale, quindi possiedi tutte le squadre delle scuole di cui sei responsabile.</span> :
    <ul>
      {props.squadre
        .filter(sq => resp.squadre.includes(sq.id))
        .map(squadra => <li key={squadra.id}>{displaySquadra(squadra, props.sessioni, props.eventi)}</li>)
      }
    </ul>
  const same = login.user.id == user.id
  return (
    <div className="card my-2 mx-1 shadow d-flex flex-column" style={extra_style}>
      <div className="card-body">
        <h3 className="card-title">
          {(princ_self && !princ_trgt) &&
            <Link to={`/modifica-responsabile/${user.id}/`}>
              <button className="btn btn-primary mr-2"><i className="fa fa-edit" /></button>
            </Link>
          }
          {user.username}
        </h3>
        <DataTable left="Nome" right={user.first_name} />
        <DataTable left="Cognome" right={user.last_name} />
        <DataTable left="Email" right={user.email} />
        <DataTable left="Username" right={user.username} />
        {same &&
          <DataTable left="Password" right={
            <span>Non puoi visualizzare la tua password perché non è memorizzata nel database, ma puoi cambiarla <a href="/accounts/password_change/">qui</a>. Per cambiarla, devi prima autenticarti come questo utente. Se l'hai dimenticata, c'è la pagina per <a href="/accounts/password_reset/">resettare la password</a>.</span>
          } />
        }
        <DataTable left="Permessi" right={<span>{badgePermessi(resp.ruolo)} {testo}</span>} />
        <DataTable left="Scuole" right={
          <ul>
            {scuole.map(
              scuola => user.responsabile.scuole.includes(scuola.id) ? <li key={scuola.id}>{displayScuola(scuola)}</li> : ""
            )}
          </ul>
        } />
        {same &&
          <DataTable left="Newsletter" right={
            resp.newsletter ? "Sì" : "No"}
          />
        }
        <DataTable left="Squadre" right={squadre_widg} />
      </div>
    </div>
  )
}


export default Responsabile;
