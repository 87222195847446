import React from 'react'

import { site_url } from '../globals'

const CookiePolicy = () => {

  return (
    <div className="container">
      <h1 className="page-header">Cookie policy</h1>
      <p>La presente Cookie Policy è relativa al sito <a href={site_url}>gas.olifis.it</a> ("Sito") gestito e operato dall'Associazione per l'Insegnamento della Fisica, con Sede legale in via Rinalda Pavoni 18, c/o Liceo Classico “Francesco Stelluti”, I – 60044 Fabriano AN - P.IVA: 01906200207</p>

      <p>I cookie sono piccoli file di testo inviati all'utente dal sito web visitato. Vengono memorizzati sull'hard disk del computer, consentendo in questo modo al sito web di riconoscere gli utenti e memorizzare determinate informazioni su di loro, al fine di permettere o migliorare il servizio offerto. Esistono diverse tipologie di cookie. Alcuni sono necessari per poter navigare sul Sito, altri hanno scopi diversi come garantire la sicurezza interna, amministrare il sistema, effettuare analisi statistiche, comprendere quali sono le sezioni del Sito che interessano maggiormente gli utenti o offrire una visita personalizzata del Sito. Il Sito utilizza cookie tecnici e non di profilazione. Quanto precede si riferisce sia al computer dell'utente sia ad ogni altro dispositivo che l'utente può utilizzare per connettersi al Sito.</p>

      <h4>Cookie tecnici</h4>

      <p>I cookie tecnici sono quelli utilizzati al solo fine di effettuare la trasmissione di una comunicazione su una rete di comunicazione elettronica, o nella misura strettamente necessaria al fornitore di un servizio della società dell'informazione esplicitamente richiesto dall'abbonato o dall'utente a erogare tale servizio. Essi non vengono utilizzati per scopi ulteriori e sono normalmente installati direttamente dal titolare o gestore del sito web. Possono essere suddivisi in cookie di navigazione o di sessione, che garantiscono la normale navigazione e fruizione del sito web (permettendo, ad esempio, di realizzare un acquisto o autenticarsi per accedere ad aree riservate); cookie analytics, assimilati ai cookie tecnici laddove utilizzati direttamente dal gestore del sito per raccogliere informazioni, in forma aggregata, sul numero degli utenti e su come questi visitano il sito stesso; cookie di funzionalità, che permettono all'utente la navigazione in funzione di una serie di criteri selezionati (ad esempio, la lingua, i prodotti selezionati per l'acquisto) al fine di migliorare il servizio reso allo stesso.</p>
      <p>La disabilitazione dei cookie potrebbe limitare la possibilità di usare il Sito e impedire di beneficiare in pieno delle funzionalità e dei servizi presenti sul Sito. Per decidere quali accettare e quali rifiutare, è illustrata di seguito una descrizione dei cookie utilizzati sul Sito.</p>

      <h4>Tipologie di cookie utilizzati</h4>
      <ul>
        <li>Cookie di prima parte: I cookie di prima parte (ovvero i cookie che appartengono al sito dell'editore che li ha creati) sono impostati dal sito web visitato dall'utente, il cui indirizzo compare nella finestra URL. L'utilizzo di tali cookie ci permette di far funzionare il sito in modo efficiente e di tracciare i modelli di comportamento dei visitatori. Cookie di terzi:I cookie di terzi sono impostati da un dominio differente da quello visitato dall'utente. Se un utente visita un sito e una società diversa invia l'informazione sfruttando quel sito, si è in presenza di cookie di terze parti.</li>
        <li>Cookie di sessione: I c.d. ‘cookie di sessione' sono memorizzati temporaneamente e vengono cancellati quando l'utente chiude il browser. Se l'utente si registra al Sito, può utilizzare cookie che raccolgono dati personali al fine di identificare l'utente in occasione di visite successive e di facilitare l'accesso - login al Sito (per esempio conservando username e password dell'utente) e la navigazione sullo stesso. Inoltre AIF. utilizza i cookie per finalità di amministrazione del sistema. Il Sito potrebbe contenere link ad altri siti. AIF. non ha alcun accesso o controllo su cookie, web bacon e altre tecnologie di tracciamento usate sui siti di terzi cui l'utente può accedere dal Sito, sulla disponibilità, su qualsiasi contenuto e materiale che è pubblicato o ottenuto attraverso tali siti e sulle relative modalità di trattamento dei dati personali; AIF. a questo proposito, considerata la mole di tali siti terzi, declina espressamente ogni relativa responsabilità. L'utente dovrebbe verificare la privacy policy dei siti di terzi cui accede dal Sito per conoscere le condizioni applicabili al trattamento dei dati personali poiché la Privacy Policy di AIF. si applica solo al Sito come sopra definito.</li>
        <li>Cookie persistenti: I cookie persistenti sono memorizzati sul dispositivo degli utenti tra le sessioni del browser, consentendo di ricordare le preferenze o le azioni dell'utente in un sito. Possono essere utilizzati per diversi scopi, ad esempio per ricordare le preferenze e le scelte quando si utilizza il Sito.</li>
        <li>Cookie essenziali: Questi cookie sono strettamente necessari per il funzionamento del Sito. Senza l'uso di tali cookie alcune parti del Sito non funzionerebbero. Comprendono, ad esempio, i cookie che consentono di accedere in aree protette del Sito. Questi cookie non raccolgono informazioni per scopi di marketing e non possono essere disattivati.</li>
        <li>Cookie funzionali: Questi cookie servono a riconoscere un utente che torna a visitare il Sito. Permettono di personalizzare i contenuti e ricordate le preferenze (ad esempio, la lingua selezionata o la regione). Questi cookie non raccolgono informazioni che possono identificare l'utente. Tutte le informazioni raccolte sono anonime.</li>
        <li>Cookie di terze parti: Visitando il Sito l'utente potrebbe ricevere sul suo dispositivo anche cookie da siti gestiti da altre organizzazioni ("c.d cookie di "terze parti"): ciò accade perché sul Sito possono essere presenti elementi come, ad esempio, immagini, mappe, specifici link a pagine web di siti o servizi web. Questi cookie possono essere inviati al browser dell'utente da società terze direttamente dal loro siti web cui è possibile accedere navigando dal Sito.</li>
      </ul>
      <p>Ai fini della trasparenza informativa, di seguito si riporta una lista dei servizi di terze parti presenti sul sito, le finalità e i link alle pagine informative dei diversi servizi:</p>

      <table className="table table-responsive">
        <thead className="thead-dark">
          <tr>
            <th>Servizio</th>
            <th>Finalità</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Google Analytics</td>
            <td>
              <p>Il Sito potrebbe utilizzare Google Analytics, un servizio di analisi del traffico web fornito da Google, Inc. (“Google”). Questo servizio viene utilizzato per raccogliere dati statistici che ci consentono di migliorare l'esperienza degli utenti del sito web. Google Analytics utilizza i "cookie" per raccogliere e analizzare informazioni relative alle modalità di interazione degli utenti con il sito web in forma aggregata (ad esempio pagine più visitate, provenienza geografica dei visitatori ecc). Google archivia le informazioni raccolte su propri server negli Stati Uniti.
                I dati generati da Google Analytics sono conservati da Google così come indicato nella Informativa reperibile al seguente link <a href="https://support.google.com/analytics/answer/6004245?hl=it">https://support.google.com/analytics/answer/6004245?hl=it</a></p>
              <p>Per consultare l'informativa privacy di Google Inc., titolare autonomo del trattamento dei dati relativi al servizio Google Analytics, si rinvia al sito Internet  <a href="http://www.google.com/intl/it/policies/privacy/">http://www.google.com/intl/it/policies/privacy/</a></p>
              L'utente può disattivare Google Analytics installando sul proprio browser il componente aggiuntivo di opt-out fornito da Google: <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a></td>
          </tr>
          <tr>
            <td>YouTube</td>
            <td>Su alcune pagine sono possono essere presenti video pubblicati su You Tube, che potrebbe installare cookie per rilevare le preferenze sull’utilizzo di questo servizio. Per maggiori informazioni su come You Tube utilizza i cookie, consultare la privacy policy <a href="http://www.google.com/intl/it/policies/privacy">http://www.google.com/intl/it/policies/privacy</a></td>
          </tr>
        </tbody>
      </table>
      <h4>Come modificare le impostazioni sui cookie</h4>

      <p>La maggior parte dei browser accetta automaticamente i cookie, ma l'utente normalmente può modificare le impostazioni per disabilitare tale funzione. È possibile bloccare tutte le tipologie di cookie, oppure accettare di riceverne soltanto alcuni e disabilitarne altri. La sezione "Opzioni" o "Preferenze" nel menu del browser permettono di evitare di ricevere cookie e altre tecnologie di tracciamento utente, e come ottenere notifica dal browser dell'attivazione di queste tecnologie. In alternativa, è anche possibile consultare la sezione “Aiuto” della barra degli strumenti presente nella maggior parte dei browser.</p>

      <p>È anche possibile selezionare il browser che utilizzato dalla lista di seguito e seguire le istruzioni:</p>

      <ul>
        <li><a href="http://windows.microsoft.com/it-it/windows7/how-to-manage-cookies-in-internet-explorer-9">Internet Explorer</a></li>
        <li><a href="https://support.google.com/chrome/answer/95647">Chrome</a></li>
        <li><a href="https://support.apple.com/it-it/HT201265">Safari</a></li>
        <li><a href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie">Firefox</a></li>
        <li><a href="http://www.opera.com/help/tutorials/security/">Opera</a>.</li>
      </ul>
      <p>Da dispositivo mobile:</p>
      <ul>
        <li><a href="https://support.google.com/chrome/answer/95647?hl=it">Android</a></li>
        <li><a href="https://support.apple.com/it-it/HT201265">Safari</a></li>
        <li><a href="http://www.windowsphone.com/it-it/how-to/wp7/web/changing-privacy-and-other-browser-settings">Windows Phone</a></li>
        <li><a href="http://docs.blackberry.com/en/smartphone_users/deliverables/32004/Turn_off_cookies_in_the_browser_60_1072866_11.jsp">Blackberry</a></li>
      </ul>
      <p>Si ricorda che la disabilitazione dei cookie di navigazione o quelli funzionali può causare il malfunzionamento del Sito e/o limitare il servizio offerto.</p>
    </div>
  )
}


export default CookiePolicy
