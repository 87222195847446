import { cloneDeep } from 'lodash';

import { WithId } from './low_level'



export const parseIntLoose = (num: number | string) => {
  return typeof num === "string" ? parseInt(num) : num;
}


export interface Cache<T extends WithId> {
  lock: boolean,
  fetched: boolean,
  results: T[]
}


export type Filtro = filtrobase[];


export interface RStructuredCache<T extends WithId> {
  filter: {
    [key: string]: Cache<T> | RStructuredCache<T>
  }
}

export type StructuredCache<T extends WithId> = Cache<T> | RStructuredCache<T>;


export interface filtrobase {
  name: string,
  value: string
}



const cache_vuota = {
  lock: false,
  fetched: false,
  results: [] as any[]
}

export const structured_cache_vuota = {
  filter: {}
}

interface objectAction<T> {
  type: string
  oggetti: T[],
  oggetto?: T,
  sorting?: (a: T, b: T) => number,
}

export interface structuredObjectAction<T> extends objectAction<T> {
  filtro?: Filtro
}


export class Esportone<T extends WithId> {
  LOCK_OBJECT: string;
  SET_OBJECT: string;
  UPDATE_OBJECT: string;
  SET_SINGOLO_OBJECT: string;
  url: () => string;
  cache: (state: any) => Cache<T>;

  constructor(nome: string, url: () => string, cache: (state: any) => Cache<T>) {
    this.LOCK_OBJECT = "LOCK_" + nome;
    this.SET_OBJECT = "SET_" + nome;
    this.UPDATE_OBJECT = "UPDATE_" + nome;
    this.SET_SINGOLO_OBJECT = "SET_SINGOLO_" + nome;
    this.url = url.bind(this);
    this.cache = cache.bind(this);
  }

  set_object_action(oggetti: T[]) {
    return {
      type: this.SET_OBJECT, oggetti: oggetti
    }
  }

  lock_object_action() {
    return {
      type: this.LOCK_OBJECT
    }
  }

  update_object_action(oggetti: T[]) {
    return {
      type: this.UPDATE_OBJECT,
      oggetti: oggetti
    }
  }

  set_singolo_object_action(oggetto: T, sorting?: (a: T, b: T) => number) {
    return {
      type: this.SET_SINGOLO_OBJECT,
      oggetto: oggetto,
      sorting: sorting
    }
  }

  objectReducer(state: Cache<T> = cloneDeep(cache_vuota), action: objectAction<T>): Cache<T> {
    if (action.type == this.SET_OBJECT) {
      const copia = cloneDeep(action.oggetti);
      return Object.assign(
        {}, {
        lock: false,
        fetched: true,
        results: copia
      });
    } else if (action.type == this.LOCK_OBJECT) {
      return Object.assign({}, {
        lock: true,
        fetched: state.fetched,
        results: state.results
      });
    } else if (action.type == this.UPDATE_OBJECT) {
      const copy = cloneDeep(state);
      for (let i = 0; i < action.oggetti.length; i++) {
        // Se c'é negli altri, copialo
        const idx = copy.results.findIndex((item) => item.id == action.oggetti[i].id)
        if (idx != -1) {
          copy.results[idx] = action.oggetti[i];
        } else {
          // Altrimenti mettilo in fondo che tanto si riordina dopo
          copy.results.push(action.oggetti[i]);
        }
      }
      copy.results.sort((a, b) => (parseIntLoose(b.id) - parseIntLoose(a.id)))
      return copy;
    } else if (action.type == this.SET_SINGOLO_OBJECT) {
      const nuovo = Object.assign({}, state);
      nuovo.results.push(action.oggetto);
      const sort_func = action.sorting || ((a: WithId, b: WithId) =>
        (parseIntLoose(a.id) - parseIntLoose(b.id)));
      nuovo.results.sort(sort_func)
      return nuovo;
    } else {
      return state;
    }
  }
}


export class StructuredEsportone<T extends WithId> {
  LOCK_OBJECT: string;
  SET_OBJECT: string;
  url: () => string;
  cache: (state: any) => StructuredCache<T>;


  constructor(nome: string, url: () => string, cache: (state: any) => StructuredCache<T>) {
    this.LOCK_OBJECT = "LOCK_" + nome;
    this.SET_OBJECT = "SET_" + nome;
    this.url = url.bind(this);
    this.cache = cache.bind(this);
  }

  lock_object_action(filtro: Filtro) {
    return {
      type: this.LOCK_OBJECT,
      filtro: filtro
    }
  }

  set_object_action(oggetti: T[], filtro: Filtro) {
    return {
      type: this.SET_OBJECT,
      oggetti: oggetti,
      filtro: filtro
    }
  }

  objectReducer(
    state: StructuredCache<T> = cloneDeep(structured_cache_vuota),
    action: structuredObjectAction<T>): StructuredCache<T> {
    const appo = (lock_or_set: string) => {
      let copia = Object.assign({}, state);
      let copia_appo = copia;
      for (let i = 0; i < action.filtro.length; i++) {
        let f = action.filtro[i].value;
        copia_appo = copia_appo as RStructuredCache<T>;
        if (!copia_appo.filter) {
          copia_appo.filter = {};
        }
        let filtrato = copia_appo.filter;
        if (!filtrato[f]) {
          filtrato[f] = {} as any;
        }
        copia_appo = filtrato[f];
      }
      copia_appo = copia_appo as Cache<T>;
      copia_appo.fetched = lock_or_set === "SET";
      copia_appo.lock = !(lock_or_set === "SET");
      copia_appo.results = lock_or_set === "SET" ? action.oggetti : [];
      return copia;
    }

    if (action.type == this.SET_OBJECT) {
      return appo("SET");
    } else if (action.type == this.LOCK_OBJECT) {
      return appo("LOCK");
    } else {
      return state;
    }
  }
}
