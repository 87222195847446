

export const parse_latex = (text: string) => {
  const baserex = "\\{((?:[^}{]|\\{(?:[^}{]|\\{(?:[^}{]|\\{[^}{]*\\})*\\})*\\})*)\\}";

  let parsed_text = (text || "")
    .replace(new RegExp(`\\\\SI${baserex}${baserex}`, "g"), "\\num{$1}\\,\\si{$2}")
  const subst = [
    ["ped", "_{\\mathrm{$1}}"],
    ["ap", "^{\\mathrm{$1}}"],
    ["abs", "\\left| $1 \\right|"],
    ["si", "\\mathrm{$1}"],
  ]

  for (const vec of subst) {
    parsed_text = parsed_text.replace(new RegExp(`\\\\${vec[0]}${baserex}`, "g"), vec[1])
  }

  parsed_text = parsed_text
    .replace(/\\num{}/g, "")
    .replace(/\\dd(\s+)/g, "\\mathrm{d}$1")
    .replace(/\\d(\s+)/g, "\\partial$1")
    .replace(/\\per/g, "/")

    .replace(/\\mA/g, "\\milli\\ampere")
    .replace(/\\cm/g, "\\centi\\meter")

    .replace(/\\meter/g, "m")
    .replace(/\\metre/g, "m")
    .replace(/\\celsius/g, "C")
    .replace(/\\kelvin/g, "K")
    .replace(/\\watt/g, "W")
    .replace(/\\ohm/g, "\\Omega ")
    .replace(/\\second/g, "s")
    .replace(/\\Pa/g, "Pa")
    .replace(/\\pascal/g, "Pa")
    .replace(/\\kilogram/g, "kg")
    .replace(/\\kg/g, "kg")
    .replace(/\\joule/g, "J")
    .replace(/\\tesla/g, "T")
    .replace(/\\gram/g, "g")
    .replace(/\\mole/g, "mol")
    .replace(/\\mol/g, "mol")
    .replace(/\\ampere/g, "A")
    .replace(/\\newton/g, "N")
    .replace(/\\N/g, "N")
    .replace(/\\farad/g, "F")
    .replace(/\\coulomb/g, "C")
    .replace(/\\electronvolt/g, "eV")
    .replace(/\\henry/g, "H")
    .replace(/\\Hz/g, "Hz")

    .replace(/\\kilo/g, "k")
    .replace(/\\mega/g, "M")
    .replace(/\\centi/g, "c")
    .replace(/\\milli/g, "m")
    .replace(/\\micro/g, "\\mu ")

    .replace(/\^cubed/g, "^3 ")

    .replace(/\[([^\]]+)\]\(([^\)]+)\)/g, "\\href{$2}{$1}")

    .replace(/\\vskip\s+([0-9]+(?:cm|pt))/g, "\\vspace{$1}")

  for (const result of [...parsed_text.matchAll(new RegExp(`\\\\num${baserex}`, "g"))]) {
    const number = result[1];
    let res;
    if (/\s*([0-9]*\.?[0-9]*)\s*(?:e\s*([+-]?[0-9]+))\s*/i.test(number)) {
      // con esponente
      res = number.replace(/\s*([0-9]*\.?[0-9]*)\s*(?:e\s*([+-]?[0-9]+))\s*/i, "$1 \\cdot 10^{$2}")
    } else {
      // senza esponente
      res = number.replace(/\s*([0-9]*\.?[0-9]*)\s*/, "$1")
    }
    parsed_text = parsed_text.replaceAll(result[0], res)
  }
  return parsed_text;
}
