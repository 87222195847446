
import { WithId } from './low_level'
import { StructuredCache, RStructuredCache, Filtro, Cache } from './redux-auto-solver'


export function get_subcache<T extends WithId>(cache: StructuredCache<T>, filter?: Filtro): Cache<T> {
  let subcache = Object.assign({}, cache);
  if (filter && filter.length > 0) {
    subcache = subcache as RStructuredCache<T>;
    for (let i = 0; i < filter.length; i++) {
      let f = filter[i].value;
      subcache = subcache as RStructuredCache<T>;
      if (subcache.filter && subcache.filter[f]) {
        subcache = subcache.filter[f];
      } else {
        subcache = {
          fetched: false,
          lock: false,
          results: []
        };
        break;
      }
    }
  } else {
    subcache = subcache as Cache<T>;
  }
  return subcache as Cache<T>;
}
