import { useRef } from 'react'
import { cloneDeep } from 'lodash'
import { DEBUG, store, connection_action, disconnection_action } from './imported_stuff'

export const ws_base_url = (debug: boolean) => (debug ? 'ws' : 'wss') + "://" + window.location.host;



export enum WebSocketState {
  Connected,
  Disconnected,
  NeverSeen,
}

export const onopen = (e: any, obj: any) => {
  obj.setState({ wss: WebSocketState.Connected });
}

export const onclose = (e: CloseEvent, obj: any, recon_func: (() => WebSocket)) => {
  if (obj.state.wss != WebSocketState.Disconnected) {
    obj.setState({ wss: WebSocketState.Disconnected, wss_last_seen: new Date() });
  }
  console.warn('Socket closed unexpectedly. Trying to reconnect', e);
  obj._reconnect = setTimeout(() => {
    obj._ws = recon_func();
    obj._ws.onclose = ((e: CloseEvent) => onclose(e, obj, recon_func));
    obj._ws.onopen = ((e: any) => onopen(e, obj));
  }, 10000);
}


export const initial_parse = (e: Event) => {
  // @ts-ignore
  const data = JSON.parse(e.data);
  const _type = data["type"]
  if (_type === "error") {
    console.error(data)
    return;
  }
  return data;
}

export interface WsStateFunctional {
  ws: WebSocket,
  wss: WebSocketState,
  ws_last_seen: Date,
  reconnect?: number,
}



export const empty_ws_state_functional: WsStateFunctional = {
  ws: null,
  wss: WebSocketState.NeverSeen,
  ws_last_seen: new Date(),
  reconnect: null,
}


export const get_new_interval = (last_seen: Date) => {
  const interval = ((new Date()).getTime() - last_seen.getTime()) / 1000;

  if (interval < 60) {
    return 5 * 1000;
  } else if (interval < 60 * 5) {
    return 60 * 1000;
  } else if (interval < 60 * 15) {
    return 60 * 2 * 1000;
  } else {
    return 60 * 5 * 1000;
  }
}



export const useWebsocket = () => useRef(cloneDeep(empty_ws_state_functional));

export const connectWsFunctional = (obj: WsStateFunctional, relative_url: string, msg_handler: (msg: any) => void) => {
  const ws = new WebSocket(`${ws_base_url(DEBUG)}${relative_url}`);
  ws.onmessage = (e) => {
    const message = initial_parse(e);
    msg_handler(message);
  }

  ws.onopen = e => {
    obj.wss = WebSocketState.Connected;
    obj.ws_last_seen = new Date();
    obj.reconnect = null;
    store.dispatch(connection_action(relative_url))
  }

  ws.onclose = e => {
    if (obj.wss != WebSocketState.Disconnected) {
      obj.wss = WebSocketState.Disconnected;
      obj.ws_last_seen = new Date();
      store.dispatch(disconnection_action(relative_url))
    }
    const new_interval = get_new_interval(obj.ws_last_seen);
    console.warn('Socket closed unexpectedly. Trying to reconnect', e);
    obj.reconnect = window.setTimeout(() => {
      connectWsFunctional(obj, relative_url, msg_handler)
    }, new_interval)
  }
  obj.ws = ws;
}


export const cleanupWs = (wsf: WsStateFunctional) => {
  if (wsf === null || wsf.ws === null) { return; }
  wsf.ws.onclose = () => { }
  wsf.ws.close()
  wsf.ws = null;
}
