import { pad } from './utils';
import { date_options, DEFAULT_LOCALE } from '../globals';

import { same_day, base_nice_date } from 'django-rest-react'
export { nice_date } from 'django-rest-react'


export function nice_date_seconds(date: Date): string {
  const vec = [
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  ]
  return vec.map(val => pad(val.toString(), 2)).join(":")
}

export function nice_date_estesa(date: Date | string): string {
  const appo = (typeof date === "string") ? new Date(date) : date;
  return appo.toLocaleDateString(DEFAULT_LOCALE, date_options);
}

function base_nice_date_discorsiva(date: Date): string {
  let data_bella;
  const adesso = new Date();
  const data_vera = base_nice_date(date);
  if (same_day(adesso, date)) {
    data_bella = `alle ${data_vera}`;
  } else {
    data_bella = `in data ${data_vera}`;
  }
  return data_bella;
}

export function nice_date_discorsiva(date: Date | string): string {
  const appo = (typeof date === "string") ? new Date(date) : date;
  return base_nice_date_discorsiva(appo);
}
