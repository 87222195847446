import React, { Component } from 'react'

import { nice_date } from '../utils'


interface IProps {
  date: string | Date
}


interface IState {
  message: string
}


class NiceDate extends Component<IProps, IState> {
  timeout: number

  constructor(props: IProps) {
    super(props)
    this.state = {
      message: ""
    }
    this.ricalcola = this.ricalcola.bind(this)
  }

  ricalcola() {
    let cusumano;
    if (typeof this.props.date == "string") {
      cusumano = new Date(this.props.date)
    } else {
      cusumano = this.props.date;
    }
    const seconds_left = (new Date().getTime() - (cusumano).getTime()) / 1000;
    let message = "";
    let prima_message = "";
    let dopo_message = "";
    let next_recompute = 3600;
    if (seconds_left < 0) {
      prima_message = "Fra "
    } else {
      dopo_message = " fa"
    }
    if (seconds_left < 60) {
      message = `${seconds_left.toFixed(0)} secondi`
      next_recompute = 1000
    } else if (seconds_left < 3600) {
      message = `${(seconds_left / 60).toFixed(0)} minuti`
      next_recompute = 1000 * 60
    } else if (seconds_left < (3600 * 24)) {
      message = `${(seconds_left / 3600).toFixed(0)} ore`
      next_recompute = 3600 * 1000
    } else {
      message = `${(seconds_left / 86400).toFixed(0)} giorni`
      next_recompute = 1000 * 86400
    }
    this.setState({ message: `${prima_message}${message}${dopo_message}` })
    this.timeout = window.setTimeout(this.ricalcola, next_recompute)
  }

  componentDidMount() {
    this.ricalcola()
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  render() {
    return (
      <div title={nice_date(this.props.date)}>{this.state.message}</div>
    )
  }
}

export default NiceDate;
