import React, { useEffect } from 'react';

import { soft_refresh_cache } from 'django-rest-react'
import { Evento } from '../api/types'
import { messaggi_importanti_all, useMessaggioImportante } from '../reducers'
import NiceDate from './NiceDate'
import MdMath from './MdMath'

interface ownProps {
  evento: Evento
}

const MessaggiImportantiList = (props: ownProps) => {
  const messaggi_importanti = useMessaggioImportante(props.evento.id)

  useEffect(() => {
    soft_refresh_cache(messaggi_importanti_all, [{ name: 'evento', value: `${props.evento.id}` }])
  }, [props.evento.id])

  const alternativa = <p>Nessun messaggio ancora pubblicato</p>
  return (
    <div className="container py-2">
      {!messaggi_importanti.length && alternativa}
      {messaggi_importanti &&
        <ul>
          {messaggi_importanti.map(item => {
            return (
              <li key={item.id}>
                <div>
                  <NiceDate date={item.data} /> <MdMath source={item.testo} />
                </div>
              </li>
            )
          })}
        </ul>}
    </div>
  );
}

export default MessaggiImportantiList;
