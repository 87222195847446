import { isEmpty } from 'lodash'


export function removeUnderscore(string: string): string {
  return string.replace(/_/g, ' ');
}

export function formNameToNice(str: string): string {
  const val = reverseString(reverseString(removeUnderscore(str)).split(".")[0])
  return val.charAt(0).toUpperCase() + val.slice(1);
}

export const get_params_to_object = (str: string) => {
  return JSON.parse('{"' + decodeURI(str.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
}


/**
 * La seguente funzione monolinea prende un array e lo flattena.
 * Si veda https://schneidenbach.gitbooks.io/typescript-cookbook/functional-programming/flattening-array-of-arrays.html
 * @return {Array} l'array flattenato
 */
export function flatten<T>(arrays: T[][]): T[] {
  return [].concat(...arrays);
}


/**
 * La seguente funzione aspetta per time millisecondi
 */
export function sleep(time: number) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export function sleep_sync(ms: number) {
  var start = new Date().getTime(), expire = start + ms;
  while (new Date().getTime() < expire) { }
  return;
}


export function titleCase(string: string): string {
  let arr = string.toLowerCase().split(' ');
  arr = arr.map(val => {
    return val.charAt(0).toUpperCase() + val.slice(1);
  });
  return arr.join(' ');
}


// // https://github.com/lodash/lodash/issues/2240
export function flattenKeys(object: any, initialPathPrefix = ''): any {
  if (isEmpty(object)) {
    return {};
  }
  if (!object || typeof object !== 'object') {
    return [{ [initialPathPrefix]: object }]
  }

  const prefix = initialPathPrefix
    ? Array.isArray(object)
      ? initialPathPrefix
      : `${initialPathPrefix}.`
    : ''

  return Object.keys(object)
    .flatMap((key) =>
      flattenKeys(
        object[key],
        Array.isArray(object) ? `${prefix}[${key}]` : `${prefix}${key}`,
      ),
    )
    .reduce((acc, path) => ({ ...acc, ...path }))
}



export const camelize = (kebab: string) => {
  const arr = kebab.split('-');
  const capital = arr.map(
    (item, index) => index ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() : item);
  const capitalString = capital.join("");
  return capitalString;
}


export function chunk<T>(arr: T[], len: number) {
  let chunks = [], i = 0, n = arr.length;
  while (i < n) {
    chunks.push(arr.slice(i, i += len));
  }
  return chunks;
}


/**
 * Questa funzione fa zero padding dei numeri.
 * pad(13, 4) = 0013
 * pad(13, 4, 2) = 2213
 */
export function pad(n: string, width: number, z?: string): string {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}


export function reverseString(str: string): string {
  return str.split("").reverse().join("")
}
