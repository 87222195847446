import React, { Component } from 'react';

import { VERSION, MAIL_CONTATTO } from '../globals';


class Footer extends Component {
  render() {
    const year = new Date().getFullYear();
    return (
      <footer className="mt-5 py-2 bg-primary justify-content-center text-center" id="footer">
        <div>
          <small className="container text-center text-white">
            Gascal {VERSION}
            <br />
            Copyright &copy; 2019-{year} Fabio Zoratti
              <br />
              Contattaci a <a href={`mailto:${MAIL_CONTATTO}`} className="text-white"><i className="fa fa-envelope mr-1 text-white"></i>{MAIL_CONTATTO}</a>.
              <br />
              Puoi segnalare un bug sul <a href="https://gitlab.com/sns-olifis/gascal/" className="text-white"><i className="fa fa-link" /> repository del progetto</a>.
            </small>
        </div>
      </footer>
    );
  }
}


export default Footer;
