// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../frontend/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../frontend/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary": "#216942",
	"secondary": "#6c757d",
	"success": "#28a745",
	"warning": "#fd7e14",
	"danger": "#dc3545",
	"teal": "#00aba9",
	"amethys": "#9b59b6",
	"emerald": "#2ecc71",
	"pumpkin": "#d35400",
	"red": "red",
	"electric-yellow": "#ff3",
	"yellow": "#fff000",
	"belize-hole": "#2e8bcc",
	"wet-asphalt": "#34495e",
	"brown": "#630",
	"magenta": "#ff0097",
	"concrete": "#95a5a6",
	"clouds": "#ecf0f1",
	"pomegranate": "#c0392b",
	"info": "#5ebdec",
	"light-primary": "#b1e7c9",
	"discord": "#7289da"
};
export default ___CSS_LOADER_EXPORT___;
