import React from 'react';
import { useSelector } from "react-redux";

import { AppState } from '../reducers';

import './LoadingBar.css';

interface StateProps {
  display: number
}

const mapStateToProps: (state: AppState) => StateProps =
  (state) => {
    return { display: state.loading.loading }
  };


const LoadingBar = () => {
  const { display } = useSelector(mapStateToProps);
  let bar;
  if (display > 0) {
    bar = <div className="loading-bar" />;
  }
  return (
    <div className="loading-bar-container">
      {bar}
    </div>
  );
}

export default LoadingBar;
