import { useSelector } from 'react-redux'

import {
  add_tentativo_action,
  add_loading, del_loading
} from './actions';
import {
  Esportone, StructuredEsportone, Filtro,
} from './redux-auto-solver';

export * from './low_level';
import {
  WithId, plain_object, make_request,
  http_get_params, http_post_params, http_delete_params, http_put_params, http_patch_params,
  PaginatedJson
} from './low_level'
import { get_subcache } from './api_utils_nostore'


// Queste sono le due dipendenze critiche che rischiano di fare dipendenze circolari.
import { reducers_map, store } from './imported_stuff';



const add_loading_action = () => { store.dispatch(add_loading()); }
const del_loading_action = () => { store.dispatch(del_loading()); }


/**
 * Shortcut per fare una richiesta get che chiama solo make_request.
 */
function get_page<T>(url: string, get_params?: plain_object) {
  return make_request<T>(url, http_get_params, undefined, get_params, add_loading_action, del_loading_action);
}

export function post_page<T>(url: string, formData: plain_object, get_params?: plain_object) {
  return make_request<T>(url, http_post_params, formData, get_params, add_loading_action, del_loading_action);
}

export function delete_page<T>(url: string, get_params?: plain_object) {
  return make_request<T>(url, http_delete_params, undefined, get_params, add_loading_action, del_loading_action);
}

export function put_page<T>(url: string, formData: plain_object, get_params?: plain_object) {
  return make_request<T>(url, http_put_params, formData, get_params, add_loading_action, del_loading_action);
}

export function patch_page<T>(url: string, formData: plain_object, get_params?: plain_object) {
  return make_request<T>(url, http_patch_params, formData, get_params, add_loading_action, del_loading_action);
}

export function get_single_json<T>(url: string, get_params?: plain_object) {
  return get_page<T>(url, get_params);
}


function next_page(jnext: string, old_params: plain_object) {
  const new_page = jnext.match(/(\?|&)page=([0-9]+)/g)[0].match(/[0-9]+/g)[0];
  const other_get_params = Object.assign({}, old_params);
  other_get_params["page"] = new_page;
  return other_get_params
}

function next_offset(jnext: string, old_params: plain_object) {
  const new_offset = jnext.match(/(\?|&)offset=([0-9]+)/g)[0].match(/[0-9]+/g)[0];
  const new_limit = jnext.match(/(\?|&)limit=([0-9]+)/g)[0].match(/[0-9]+/g)[0];
  const other_get_params = Object.assign({}, old_params);
  other_get_params["offset"] = new_offset;
  other_get_params["limit"] = new_limit;
  return other_get_params
}


export function get_page_json<T>(url: string, pagenum: number, get_params?: plain_object) {
  let realparams = Object.assign({}, get_params)
  realparams["page"] = pagenum
  return get_page<PaginatedJson<T>>(url, realparams)
}


export function get_paginated_json<T>(url: string, get_params?: plain_object): Promise<T[]> {
  return get_page<PaginatedJson<T>>(url, get_params).then(json => {
    let result;
    if (json["next"] != null) {
      const nextfunc = json["next"].includes("offset") ? next_offset : next_page;
      const other_get_params = nextfunc(json["next"], get_params)
      result = get_paginated_json<T>(
        url, other_get_params).then(function(other_json: T[]): T[] {
          return json["results"].concat(...other_json);
        });
    } else {
      result = json["results"];
    }
    return result;
  });
}




export function display_cached<T extends WithId>(
  oggetto: Esp<T>, pk: number,
  display: (a: T) => string,
  refresh_action?: () => any,
  filter?: Filtro): string {
  if (!pk) {
    console.warn("Chiamato display_cached senza pk");
    return "";
  }
  const state = store.getState();
  const t = store.getState().tentativi;
  const appo = () => soft_refresh_cache(oggetto, filter);
  const refresh = refresh_action || appo;
  let tentativi = 0;
  if (t) {
    tentativi = t[pk] ? t[pk] : 0;
  }
  const cache = oggetto.cache(state);
  let subcache = get_subcache<T>(cache, filter);
  if (!subcache.fetched || subcache.lock) {
    if (tentativi < 2) {
      refresh();
      store.dispatch(add_tentativo_action(pk));
      return "";
    } else { return ""; }
  }
  const match = subcache.results.find((item: T) => { return item.id == pk });
  if (match) {
    return display(match);
  } else {
    if (tentativi >= 1) {
      console.warn("Elemento non matchato", pk, subcache);
    }
  }
}


type Esp<T extends WithId> = Esportone<T> | StructuredEsportone<T>;


export function useFilteredFactory<T extends WithId>(name: string, do_refresh: boolean, filter?: Filtro): T[] {
  const cache = useSelector((state: any) => state[name]);
  const oggetto = reducers_map[name];
  let subcache = get_subcache<T>(cache, filter);
  if (subcache.fetched || subcache.lock) {
    return subcache.results;
  }
  if (do_refresh) {
    refresh_cache<T>(oggetto, filter);
  }
  return [];
}

export const useFiltered = <T extends WithId>(name: string, filter?: Filtro) => useFilteredFactory<T>(name, true, filter);
export const useFilteredNoRefresh = <T extends WithId>(name: string, filter?: Filtro) => useFilteredFactory<T>(name, false, filter);

export function get_cached_item_from_cache<T extends WithId>(
  name: string, filter?: Filtro): T[] {
  const oggetto = reducers_map[name];
  const cache = oggetto.cache(store.getState());
  let subcache = get_subcache<T>(cache, filter);
  if (subcache.fetched || subcache.lock) {
    return subcache.results;
  }
  refresh_cache<T>(oggetto, filter);
  return [];
}


export function get_cached_item<T extends WithId>(
  state: any, oggetto: Esp<T>, filter?: Filtro, skip_refresh: boolean = false): T[] {
  const cache = oggetto.cache(state);
  let subcache = get_subcache<T>(cache, filter);
  // Ritorno i risultati di prima,
  // tanto è già in corso un fetch per prendere
  // quelli veri che verrano automaticamente refreshati
  if (subcache.fetched || subcache.lock) {
    return subcache.results;
  }
  if (!skip_refresh) {
    refresh_cache(oggetto, filter);
  }
  return [];
}


export function get_cached_detail_item<T extends WithId>(
  state: any, oggetto: Esp<T>, pk: number, filter?: Filtro): T {
  const cache = oggetto.cache(state);
  let subcache = get_subcache<T>(cache, filter);
  const match = subcache.results.find((item: T) => {
    return item.id == pk;
  });
  if (match) { return match; }
  return null;
}


export async function refresh_cache<T extends WithId>(oggetto: Esp<T>, filter?: Filtro) {
  store.dispatch(add_loading());
  store.dispatch(oggetto.lock_object_action.bind(oggetto)(filter));
  const url = oggetto.url();
  let fetched;
  if (filter) {
    const reduce: (f: Filtro) => plain_object = (f) => {
      let ret: plain_object = {};
      for (let i = 0; i < f.length; i++) {
        ret[f[i].name] = f[i].value;
      }
      return ret;
    }
    fetched = get_paginated_json<T>(url, reduce(filter));
  } else {
    fetched = get_paginated_json<T>(url);
  }
  return fetched.then(results => {
    // Qui toglie il lock
    store.dispatch(del_loading());
    store.dispatch(oggetto.set_object_action.bind(oggetto)(results, filter));
    return results;
  });
}

export function soft_refresh_cache<T extends WithId>(oggetto: Esp<T>, filter?: Filtro) {
  const cache = oggetto.cache(store.getState());
  let subcache = get_subcache<T>(cache, filter);
  if (subcache.fetched || subcache.lock) {
    return subcache.results;
  }
  refresh_cache(oggetto, filter);
  return [];
}
