import React, { useEffect } from 'react';

import { Tile } from '../components'
import { testing_or_dummy } from '../globals'


const Regolamento = () => {
  useEffect(() => {
    document.title = "Regolamento - GAS";
  }, [])

  const ANNI = [2025, 2024, 2023];

  return (
    <div className="container">
      <h1 className="page-header">Regolamento</h1>
      <p>Qui sotto alleghiamo le regole della competizione, anno per anno, in formato PDF.</p>
      {ANNI.map(year => {

        const regolamento = testing_or_dummy(`regolamento-${year}`);
        const istruzioni = testing_or_dummy(`istruzioni-${year}`);
        return (
          <React.Fragment key={year}>
            <h1>{year}</h1>
            <div className="d-flex flex-wrap justify-content-center">
              <Tile
                url={regolamento}
                absolute={true}
                color="info" text={`Regolamento ${year}`}
                icon="fa-balance-scale"
              />
              <Tile
                url={istruzioni}
                absolute={true}
                color="wet-asphalt" text={`Logistica ${year}`}
                icon="fa-cogs"
              />
            </div>
          </React.Fragment>
        )
      })}

    </div>
  );
}

export default Regolamento;
