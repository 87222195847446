import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { EventoPunteggi, SquadraInSessione, Problema } from '../api/types'

import { AppState } from '../reducers'
import { themeColors as colors } from '../globals'


interface ownProps {
  problemi: Problema[],
  squadre: SquadraInSessione[],
  timer: any,
  session_pk: number,
}


type IProps = ownProps;

const get_last: (e: EventoPunteggi[] | null) => EventoPunteggi | null = (eventi) => {
  if (!eventi) return null
  if (eventi.length === 0) return null
  const adesso = new Date();
  const last = eventi[eventi.length - 1]
  if ((adesso.getTime() - last.date.getTime()) > (1000 * 10)) {
    return null;
  }
  return last;
}


const common = {
  width: '600px', height: '180px',
  marginRight: '200px', marginTop: '50px',
  borderStyle: 'solid',
}

const RispostaFeed = (props: IProps) => {
  const { squadre } = props;
  // force update
  const [lastUpd, setLastUpd] = useState(0);

  const eventi = useSelector((state: AppState) => state.punteggi_markov[props.session_pk]?.eventi);
  const last = get_last(eventi)
  // Force update every second
  useEffect(() => {
    setTimeout(() => setLastUpd((new Date()).getTime()), 1000);
  }, [lastUpd])

  if (!last) {
    return <div style={{ padding: '10px 0', ...common }}>{props.timer}</div>
  }

  const squadra = squadre.find(sq => sq.id === last.squadra)
  const color = last.kind == "r" ? (last.corretto ? colors["success"] : colors["danger"]) : colors["yellow"];

  const stile = {
    backgroundColor: color,
    textAlign: "center" as "center",
    padding: '30px 0',
    ...common,
  }

  return (
    <div style={stile}>
      <h1>P{last.problema + 1}</h1>
      <h2>{squadra?.nome}</h2>
    </div>
  )
}

export default RispostaFeed
