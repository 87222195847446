import React, { Component } from 'react';
import Toast from 'react-bootstrap/Toast';

import { nice_time_interval } from 'django-rest-react';
import { del_toast_action, ToastTypeWithId } from '../actions';
import store from '../store';


interface ownProps extends ToastTypeWithId {
}

interface IState {
  start: number
}

type IProps = ownProps;



export default class ToastWithTime extends Component<IProps, IState> {
  timer: number

  constructor(props: IProps) {
    super(props);
    const last = this.props.time || new Date();

    this.state = {
      start: Math.round(((new Date()).getTime() - last.getTime()) / 1000)
    }
  }


  componentDidMount() {
    this.timer = window.setInterval(() => this.setState({ start: this.state.start + 1 }), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.timer);
  }


  render() {
    return (
      <Toast
        onClose={() => store.dispatch(del_toast_action(this.props.id))}
      >
        <Toast.Header>
          <strong className="mr-3">{this.props.title}</strong>
          <small>{nice_time_interval(this.state.start)}</small>
        </Toast.Header>
        <Toast.Body>{this.props.message}</Toast.Body>
      </Toast>
    );
  }
}
