import React, { Component } from 'react';

import { Squadra, SquadraUfficiale } from '../api/types';
import { DataTable } from '../components'

interface IProps {
  squadra: Squadra
  ufficiale: boolean,
}

const display = (sq: SquadraUfficiale, s: "capitano" | "consegnatore" | "p1" | "p2" | "p3") => {
  let retval = "";
  // @ts-ignore
  if (sq.hasOwnProperty(`anno_${s}`) && sq[`anno_${s}`]) {
    // @ts-ignore
    const anno = sq[`anno_${s}`];
    retval += `${anno} - `
  }
  if (sq.hasOwnProperty(s) && sq[s]) {
    const pers = sq[s];
    retval += pers
  }
  retval = retval ? retval : "Nessuno";
  return retval;
};


const SquadraDettagli = (props: IProps) => {
  let sq = props.squadra;
  // Questo check controlla se la squadra è ufficiale.
  let dettagli;
  if (props.ufficiale && sq.hasOwnProperty("capitano")) {
    let s = sq as SquadraUfficiale;
    dettagli = (
      <>
        <DataTable left="Capitano" right={display(s, "capitano")} />
        <DataTable left="Consegnatore" right={display(s, "consegnatore")} />
        <DataTable left="P1" right={display(s, "p1")} />
        <DataTable left="P2" right={display(s, "p2")} />
        <DataTable left="P3" right={display(s, "p3")} />
      </>
    );
  }
  return (
    <div className="card my-2 mx-2 shadow">
      <div className="card-body">
        <h3 className="card-title">{sq.nome}</h3>
        {dettagli}
      </div>
    </div>
  );
}

export default SquadraDettagli;
