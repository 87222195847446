import React, { Component } from 'react';
import { MapContainer as Map, Marker, Popup, TileLayer } from 'react-leaflet';

import { name_to_offsets } from '../utils'


import { SquadraInSessione as Squadra, SquadraTabella, City } from '../api/types';

const enterprise = [43.716009, 10.395730] as [number, number];

type get_coord = (s: Squadra) => [number, number];
const get_city: (s: Squadra) => City =
  s => s.scuola.indirizzo.citta
const get_coords: get_coord = s => {
  const [off_lat, off_lon] = name_to_offsets(s.nome);
  const indirizzo = s.scuola.indirizzo;
  if (!!indirizzo.latitude && !!indirizzo.longitude) {
    return [parseFloat(indirizzo.latitude) + off_lat, parseFloat(indirizzo.longitude) + off_lon]
  }
  const c = get_city(s);
  if (!!c.latitude && !!c.longitude) {
    return [parseFloat(c.latitude) + off_lat, parseFloat(c.longitude) + off_lon];
  }
  return enterprise
}


interface IProps {
  punteggi: SquadraTabella[]
  nomi: { [squadra: string]: string; },
  squadre: Squadra[]
}


const MappaLeaflet = (props: IProps) => {
  const { punteggi } = props;
  const dati = punteggi.map(item => {
    const squadra = props.squadre.filter(sq => !!sq.scuola.indirizzo).find(i => i.nome == item.nome)
    const coords = squadra ? get_coords(squadra) : enterprise;
    return {
      nome: props.nomi[item.nome],
      punti: item.totale,
      coords: coords,
    }
  })
  return (
    <div className="overflow-auto">
      <Map center={enterprise} zoom={6} style={{ height: '700px', width: '1000px' }}>
        <TileLayer
          url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
          attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
        />
        {dati.map(item =>
          <Marker position={item.coords} key={item.nome}>
            <Popup>{item.nome}<br />Punteggio: {item.punti}</Popup>
          </Marker>
        )}
      </Map>
    </div>
  )
}

export default MappaLeaflet
