import React, { useState } from 'react';


const Spoiler = (props: { children: React.ReactNode }) => {
  const [show, setShow] = useState(false);

  const inside = show ?

    <React.Fragment>
      <div>
        <p className="text-warning mb-0">
          <small>Chiudi Spoiler</small>
        </p>
        <span className='border border-warning px-1 py-1' style={{ display: 'inline-block' }}>
          {props.children}
        </span>
      </div>
    </React.Fragment>
    :
    <span className="badge badge-warning">Spoiler</span>;
  return (
    <span onClick={() => setShow(!show)}>
      {inside}
    </span>
  );
}

export default Spoiler
