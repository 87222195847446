import { cloneDeep } from 'lodash';

import { ADD_TOAST, DEL_TOAST, DEL_MULTIPLE_TOAST } from '../actions/action-types';
import { ToastAction, ToastTypeWithId } from '../actions'


export interface ToastState {
  list: ToastTypeWithId[]
}

const initial_state = {
  list: [] as ToastTypeWithId[],
}


export function toastReducer(state: ToastState = initial_state, action: ToastAction) {
  if (action.type == ADD_TOAST) {
    const new_toast = action.toast as ToastTypeWithId;
    const last_id = (state.list.length > 0) ?
      Math.max(...state.list.map(item => item.id)) :
      -1;
    new_toast.id = last_id + 1;
    const nuova_lista = Object.assign(
      [],
      [action.toast, ...state.list]
    )
    const copia = cloneDeep(state);
    copia.list = nuova_lista;
    return copia;
  } else if (action.type == DEL_TOAST) {
    const copia = cloneDeep(state);
    copia.list = copia.list.filter(item => item.id != action.index)
    return copia;
  } else if (action.type == DEL_MULTIPLE_TOAST) {
    const copia = cloneDeep(state);
    copia.list = copia.list.filter(item => (item.title != action.title
      || item.generating_object != action.generating_object))
    return copia;
  } else {
    return state;
  }
}
