import React, { Component } from 'react';
import Toast from 'react-bootstrap/Toast';

import { toast_style } from '../globals';

interface IProps {
  obj: any
}

const ThrottleToast = (props: IProps) => {
  return (
    // @ts-ignore
    <Toast style={toast_style} onClose={() => props.obj.setState({ throttle_error: null })} autohide delay={10000}>
      <Toast.Header>
        <strong className="mr-auto">Calma</strong>
        <small>Adesso</small>
      </Toast.Header>
      <Toast.Body>Stai refreshando la pagina troppo spesso, la richiesta è stata limitata.</Toast.Body>
    </Toast>
  );
}

export default ThrottleToast
