import { ADD_TOAST, DEL_TOAST, DEL_MULTIPLE_TOAST } from './action-types'


export interface ToastType {
  title: string,
  message: string | React.ReactComponentElement<any>,
  time?: Date,
  generating_object?: string,
}

export interface ToastTypeWithId extends ToastType {
  id: number
}


export interface ToastAction {
  type: typeof ADD_TOAST | typeof DEL_TOAST,
  toast?: ToastType,
  index?: number,
  title?: string,
  generating_object?: string,
}


export function add_toast_action(toast: ToastType) {
  return {
    type: ADD_TOAST,
    toast: toast,
  }
}

export function del_toast_action(toast: number) {
  return {
    type: DEL_TOAST,
    index: toast,
  }
}


export function delete_old_toasts(toast: ToastType) {
  return {
    type: DEL_MULTIPLE_TOAST,
    title: toast.title,
    generating_object: toast.generating_object,
  }
}
