import { cloneDeep } from 'lodash'

import {
  structuredObjectAction, structured_cache_vuota, get_subcache,
  StructuredCache
} from 'django-rest-react';
import { SET_SINGOLO_MESSAGGIO_IMPORTANTE } from '../actions/action-types';
import { SingoloMessaggioAction } from '../actions'
import { MessaggioImportante } from '../api/types'
import { messaggi_importanti_all } from './cache'


export function messaggiImportantiReducer(
  state: StructuredCache<MessaggioImportante> = cloneDeep(structured_cache_vuota),
  action: structuredObjectAction<MessaggioImportante> | SingoloMessaggioAction) {


  const ret = messaggi_importanti_all.objectReducer.bind(messaggi_importanti_all)(state, action);
  if (action.type == SET_SINGOLO_MESSAGGIO_IMPORTANTE) {
    const casted_action = action as SingoloMessaggioAction;
    const nuovo = Object.assign({}, ret);


    let nuovi_risultati = Object.assign([], get_subcache(nuovo, casted_action.filtro).results);
    nuovi_risultati.push(casted_action.messaggio);
    nuovi_risultati.sort((a, b) => {
      const da = new Date(a.data);
      const db = new Date(b.data);
      return (db.getTime() - da.getTime())
    })
    if (casted_action.filtro && casted_action.filtro.length > 0) {
      let copia_appo = nuovo;
      for (let i = 0; i < action.filtro.length; i++) {
        let f = action.filtro[i].value;
        if (!copia_appo.filter) {
          copia_appo.filter = {};
        }
        let filtrato = copia_appo.filter;
        if (!filtrato[f]) {
          filtrato[f] = {} as any;
        }
        copia_appo = filtrato[f];
      }
      copia_appo.fetched = true;
      copia_appo.lock = false;
      copia_appo.results = nuovi_risultati;
    }
    return nuovo;
  } else {
    return ret;
  }

}
