import {
  PERFORM_LOGIN, SET_PUNTEGGIO,
  SET_TUTTI_PUNTEGGI,
  SET_SINGOLO_MESSAGGIO_IMPORTANTE
} from './action-types';
import {
  UserWhoami, PunteggiElaborati, MessaggioImportante
} from '../api/types'
import {
  Filtro,
  loadingAction, TentativoAction,
  add_loading, del_loading
} from 'django-rest-react';
export * from './toast'


export interface performLoginAction {
  type: typeof PERFORM_LOGIN,
  user: UserWhoami
}

export function set_user_data(user: UserWhoami): performLoginAction {
  return { type: PERFORM_LOGIN, user: user };
}

export function set_punteggio_action(punteggio: PunteggiElaborati, pk: number) {
  return {
    type: SET_PUNTEGGIO,
    punteggio: punteggio,
    sessione: pk
  }
}

export function set_tutti_punteggi_action(punteggi: PunteggiElaborati[], pk: number) {
  return {
    type: SET_TUTTI_PUNTEGGI,
    punteggi: punteggi,
    sessione: pk,
  }
}

export interface PunteggioAction {
  type: typeof SET_PUNTEGGIO,
  punteggio: PunteggiElaborati,
  sessione: number
}

export interface TuttiPunteggiAction {
  type: typeof SET_TUTTI_PUNTEGGI
  punteggi: PunteggiElaborati[]
  sessione: number
}


export interface SingoloMessaggioAction {
  type: typeof SET_SINGOLO_MESSAGGIO_IMPORTANTE,
  filtro: Filtro,
  messaggio: MessaggioImportante
}



export function set_singolo_messaggio_importante(filtro: Filtro, messaggio: MessaggioImportante) {
  return {
    type: SET_SINGOLO_MESSAGGIO_IMPORTANTE,
    messaggio: messaggio,
    filtro: filtro,
  }
}



export * from './toast'
export * from './punteggi_markov'
