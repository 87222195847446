import React from 'react';
import { useLocation } from 'react-router-dom'



const NoMatch = () => {
  const location = useLocation();
  document.title = "Pagina non trovata - GaS";
  return (
    <div className="container">
      <h1 className="page-header">Error 404</h1>
      <p>Nessun risultato per <code>{location.pathname}</code></p>
    </div>
  );
}

export default NoMatch
