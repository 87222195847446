import React, { useState } from 'react'
import { Formik, Form, FormikHelpers as FormikActions } from 'formik';

import { handle_drf_form_error, sleep } from "django-rest-react";
import { FormGroupBootstrap, ErrorBlockGeneric, ConfirmedButton } from '../common-components'
import { displaySessione } from '../globals'
import { Sessione, JsonTimestamp } from '../api/types'
import { useSessione, useEvento } from '../reducers'
import { request_frontend_refresh } from '../api';



interface FormValues {
  sessione: number,
  interval: number,
}

const RequestRefresh = (props: {}) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const sessioni = useSessione(props);
  const eventi = useEvento(props);
  const initial_values = {
    sessione: null as number,
    interval: 30,
  };


  return (
    <div>
      <h1 className="page-header">
        Richiedi refresh dei client
      </h1>
      <ErrorBlockGeneric error={error} />
      {success && <div className="alert alert-success" role="alert">Refresh schedulato</div>}
      <Formik
        onSubmit={(values: FormValues, actions: FormikActions<FormValues>) => {
          // @ts-ignore
          request_frontend_refresh(values.sessione, { interval: values.interval })
            .then(() => setSuccess("ok"))
            .catch(e => handle_drf_form_error(e, actions, setError))
            .finally(() => {
              actions.setSubmitting(false);
              sleep(10000).then(() => setError(null));
            })
        }}
        initialValues={initial_values}
      >
        {({ isSubmitting }) => {
          return (
            <Form className="form col-md needs-validation">
              <FormGroupBootstrap
                type="select" name="sessione" choices={sessioni} search
                displayChoice={(ses: Sessione) => displaySessione(ses, eventi)}
              />
              <FormGroupBootstrap
                type="number" name="interval" step={5} help_text="In secondi, uniformemente distribuito sui client"
              />
              <div className="d-flex justify-content-between bd-highlight mb-3">
                <button
                  disabled={isSubmitting}
                  className="mr-auto btn btn-primary"
                  type="submit"
                >
                  Invia
                </button>
                <button
                  type="reset"
                  className="ml-auto btn btn-warning">Reset
                </button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}


export default RequestRefresh;
