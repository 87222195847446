export const PERFORM_LOGIN = "PERFORM_LOGIN";

export const SET_PUNTEGGIO = "SET_PUNTEGGIO";
export const SET_TUTTI_PUNTEGGI = "SET_TUTTI_PUNTEGGI";
export const SET_SINGOLO_MESSAGGIO = "SET_SINGOLO_MESSAGGIO";

export const SET_SINGOLO_MESSAGGIO_IMPORTANTE = "SET_SINGOLO_MESSAGGIO_IMPORTANTE";

export const ADD_TOAST = "ADD_TOAST";
export const DEL_TOAST = "DEL_TOAST";
export const DEL_MULTIPLE_TOAST = "DEL_MULTIPLE_TOAST";


export const SET_PUNTEGGI_SQUADRE_PROBLEMI = "SET_PUNTEGGI_SQUADRE_PROBLEMI";
export const ADD_PUNTEGGI_EVENTO = "ADD_PUNTEGGI_EVENTO";
export const SET_MARKOV_STATO = "SET_MARKOV_STATO";
export const RESET_EVENTI = "RESET_EVENTI";
export const CHANGE_PARAMETERS = "CHANGE_PARAMETERS";
