import React, { useState, useEffect } from 'react'
import { Formik, Form, FormikHelpers as FormikActions } from 'formik';

import { handle_drf_form_error } from 'django-rest-react'
import { FormGroupBootstrap, ErrorBlockGeneric } from '../common-components'
import { SquadraForm, Sessione } from '../api/types'
import { post_squadra } from '../api'
import { useSessione, useEvento, useScuola } from '../reducers'
import { displayScuola } from '../utils'
import store from '../store'
import { add_toast_action } from '../actions'
import { get_evento } from '../api/auto-apis';


type FormValues = SquadraForm;

const IscrizioneSquadra = (props: {}) => {
  const [error, setError] = useState(null);
  const all_sessioni = useSessione(props);
  const [eventi, setEventi] = useState([]);
  const scuole = useScuola(props);

  useEffect(() => {
    get_evento({}).then(setEventi)
  }, [])


  const eventi_attivi = eventi.filter(ev => {
    const now = new Date()
    const end = new Date(ev.fine_iscrizioni)
    const start = new Date(ev.inizio_iscrizioni);
    return now.getTime() > start.getTime() && now.getTime() < end.getTime()
  })

  const sessioni = all_sessioni.filter(ses => {
    const evento = eventi.find(ev => ev.id === ses.evento)
    if (evento?.tipologia === "o") return !ses.ufficiale;
    return eventi_attivi.map(ev => ev.id).includes(ses.evento)
  })

  const displaySessione = (ses: Sessione) => {
    const ev = eventi.find(e => e.id == ses.evento);
    if (!ev) return ""
    return `${ev.titolo} - ${ses.nome}`
  }

  const initialValues = {
    nome: "",
    sessione: sessioni.length === 1 ? sessioni[0].id : undefined,
    scuola: scuole.length === 1 ? scuole[0].id : undefined,
  }

  return (
    <div className="container">
      <h1 className="page-header">Iscrizione squadra</h1>
      <Formik
        onSubmit={(values: FormValues, actions: FormikActions<FormValues>) => {
          post_squadra(values)
            .then(sq => {
              store.dispatch(add_toast_action({
                title: "Squadra iscritta",
                message: `Hai iscritto la squadra ${sq.nome} con successo`,
              }))
            })
            .catch(err => handle_drf_form_error(err, actions, s => setError(s)))
            .finally(() => actions.setSubmitting(false))
        }}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ isSubmitting }) => {

          return (
            <Form className="needs-validation">
              <FormGroupBootstrap type="text" name="nome" />
              <FormGroupBootstrap
                type="select" name="sessione" choices={sessioni} displayChoice={displaySessione}
                help_text={
                  "Puoi iscriverti solo alle sessioni non ufficiali da questo sito, per gli ufficiali OliFis devi registrare le squadre su myOlifis." +
                  "Inoltre, in questo box troverai solo le sessioni per cui le iscrizioni sono effettivamente attive, controlla se sei nell'intervallo giusto per l'iscrizione."
                }
              />
              <FormGroupBootstrap
                type="select" name="scuola" choices={scuole} displayChoice={displayScuola}
                help_text="La scuola è irrilevante per le competizioni non ufficiali, può semplicemente essere usata a fini statistici"
              />
              <div className="d-flex justify-content-between bd-highlight mb-3">
                <button
                  type="reset"
                  className="btn btn-warning">Reset
                </button>
                <button
                  disabled={isSubmitting}
                  className="btn btn-primary"
                  type="submit"
                >
                  Invia
                </button>
              </div>
              <ErrorBlockGeneric error={error} />
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default IscrizioneSquadra
