
import store from '../store';


interface WorkerEvent extends Event {
  data: string
}






export const service_worker_handler = (event: WorkerEvent) => {


}
