import React, { useState, useEffect } from 'react'

import { simil_header_style, developers, direzione } from '../globals'
import { FotoContributor } from '../components'
import { chunk, rgb_to_rgba } from '../utils'
import { get_top_contributors } from '../api';
import { Contributor } from '../api/types';

const get_division_from_window_size = () => {
  const horiz = (window.innerWidth > window.innerHeight);
  return {
    divisione_developers: horiz ? 3 : 1,
    divisione_contributors: horiz ? 4 : 2,
    size_developer: horiz ? 10 : 40,
    size_contributor: horiz ? 8 : 20,
  }
}

const deduplicate = (contributors: Contributor[], target: Contributor) => {
  return contributors.find(c => c.name === target.name) || target
}


const ChiSiamo = () => {
  const [contributors, setContributors] = useState([])
  const [horiz, setHoriz] = useState(window.innerWidth > window.innerHeight);
  const { divisione_developers, divisione_contributors, size_developer,
    size_contributor } = get_division_from_window_size();


  useEffect(() => {
    document.title = "Chi siamo - GAS";
    get_top_contributors().then(ans => setContributors(JSON.parse(ans.json) as Contributor[]))
  }, [])

  useEffect(() => {
    const handleResize = () => {
      const horiz2 = window.innerWidth > window.innerHeight;
      if (horiz != horiz2) {
        setHoriz(horiz2)
      }
    }
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [horiz])

  const _direzione = direzione.map(p => deduplicate(contributors, p as Contributor))
  const _developers = developers.map(p => deduplicate(contributors, p as Contributor))

  return (
    <div className="container">
      <h1 className="page-header">Chi siamo</h1>

      <div>
        <div className="py-4">
          <div style={simil_header_style}>
            Direzione
          </div>
          <p className="text-muted text-center">
            Le persone che hanno diretto i lavori di organizzazione della competizione
          </p>
          <div>
            {chunk(_direzione, divisione_developers).map((chunk, idx1) =>
              <div className="flex-row" key={idx1}>
                {chunk.map((pers, idx2) =>
                  <FotoContributor
                    {...pers}
                    class_name="foto-contributor"
                    size={size_developer}
                    key={idx2}
                  />

                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
        <div className="container py-4">
          <div style={simil_header_style}>
            Ideatori dei problemi
          </div>
          <p className="text-muted text-center">Un grosso team di ex-partecipanti alle Olimpiadi della Fisica ha contribuito alla stesura e alla revisione dei problemi. La maggior parte sono allievi ed alumni della Scuola Normale Superiore, ma abbiamo collaborazione anche dalla Scuola Galileiana di Studi Superiori, Cambridge e dalla SISSA. Riportiamo qui i 16 collaboratori più attivi.</p>
          <br />
          {chunk(contributors, divisione_contributors).map((item, idx1) =>
            <div className="flex-row" key={idx1}>
              {item.map((iitem, idx2) =>
                <FotoContributor
                  {...iitem}
                  size={size_contributor} class_name="foto-propositore-problemi"
                  key={idx2}
                />)}
            </div>
          )}
        </div>
      </div>

      <div id="section-developer-team">
        <div id="section-developer-team-content" className="py-4">
          <div style={simil_header_style}>
            Developer Team
          </div>
          <div className="container">
            <p className="text-muted text-center">Questo sito e il resto dell'architettura per gestire e organizzare la gara a squadre è stato realizzato da noi, utilizzando frameworks open source.</p>
            {chunk(_developers, divisione_developers).map((item, idx1) =>
              <div className="flex-row" key={idx1}>
                {item.map((iitem, idx2) =>
                  <FotoContributor
                    {...iitem}
                    class_name="foto-contributor" size={size_developer}
                    key={idx2}
                  />)}
              </div>
            )}
          </div>
        </div>
      </div>


      <div id="section-thanks">
        <div id="section-special-thanks" className="py-4">
          <div style={simil_header_style}>
            Ringraziamenti esterni
          </div>
          <div className="container">
            <p>Ringraziamo caldamente il Prof. Sandro Campigotto, responsabile del progetto <a href="https://www.phiquadro.it/">PhiQuadro</a>, per averci fornito l'ispirazione e del materiale utile all'organizzazione della GaS.</p>
            <p>Si ringrazia inoltre <a href="https://github.com/drw0if">Aleandro Prudenzano</a> per ulteriori contributi al software.</p>
          </div>
        </div>
      </div>

    </div>
  )
}

export default ChiSiamo;
