
import { Displayable } from 'django-rest-react';

export const EVENTO_TIPI_EVENTO: Displayable[] = [
  { id: 'o', display: 'Evento ufficiale OliFis' }, 
  { id: 'pub', display: 'Evento pubblico non OliFis' }, 
  { id: 'priv', display: 'Evento privato non OliFis' }, 
];

export const EVENTO_TIPI_EVENTO_OLIFIS: Displayable[] = [
  { id: 'qo', display: 'Qualifica online' }, 
  { id: 'fn', display: 'Finale nazionale' }, 
  { id: 'a', display: 'Allenamento' }, 
];

export const INTERVALSCHEDULE_PERIOD_CHOICES: Displayable[] = [
  { id: 'days', display: 'Days' }, 
  { id: 'hours', display: 'Hours' }, 
  { id: 'minutes', display: 'Minutes' }, 
  { id: 'seconds', display: 'Seconds' }, 
  { id: 'microseconds', display: 'Microseconds' }, 
];

export const RESPONSABILE_RUOLI: Displayable[] = [
  { id: 'sp', display: 'Scolastico principale' }, 
  { id: 'ss', display: 'Scolastico secondario' }, 
];

export const SCUOLA_TIPI_SCUOLA: Displayable[] = [
  { id: 'LC', display: 'Liceo Classico' }, 
  { id: 'LS', display: 'Liceo Scientifico' }, 
  { id: 'LA', display: 'Liceo Artistico' }, 
  { id: 'LL', display: 'Liceo Linguistico' }, 
  { id: 'LM', display: 'Liceo Musicale e Coreutico' }, 
  { id: 'LU', display: 'Liceo delle Scienze Umane' }, 
  { id: 'LSp', display: 'Liceo Sportivo' }, 
  { id: 'ITE', display: 'Istituto Tecnico settore Economico' }, 
  { id: 'ITT', display: 'Istituto Tecnico settore Tecnologico' }, 
  { id: 'IPS', display: 'Istituto Professionale settore dei Servizi' }, 
  { id: 'IPI', display: 'Istituto Professionale settore Industria e Artigianato' }, 
  { id: 'IIS', display: 'Istituto Istruzione Superiore' }, 
  { id: 'IO', display: 'Istituto Omnicomprensivo' }, 
  { id: 'A', display: 'Altro' }, 
];

export const SQUADRA_ANNI_SCOLASTICI: Displayable[] = [
  { id: '1', display: 'Primo' }, 
  { id: '2', display: 'Secondo' }, 
  { id: '3', display: 'Terzo' }, 
  { id: '4', display: 'Quarto' }, 
  { id: '5', display: 'Quinto' }, 
];