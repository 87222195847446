export const ADD_LOADING = "ADD_LOADING";
export const DEL_LOADING = "DEL_LOADING";

export const RESET_TENTATIVO = "RESET_TENTATIVO";
export const NUOVO_TENTATIVO = "NUOVO_TENTATIVO";



export interface loadingAction {
  type: typeof DEL_LOADING | typeof ADD_LOADING
}

export function del_loading() {
  return {
    type: DEL_LOADING
  }
}

export function add_loading() {
  return {
    type: ADD_LOADING
  }
}


export interface TentativoAction {
  type: typeof NUOVO_TENTATIVO | typeof RESET_TENTATIVO,
  index: number,
}


export function add_tentativo_action(pk: number) {
  return {
    type: NUOVO_TENTATIVO,
    index: pk
  }
}

export function reset_tentativo_action(pk: number) {
  return {
    type: RESET_TENTATIVO,
    index: pk
  }
}
