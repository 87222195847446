import React, { useEffect, useState } from 'react';

import './FlipClock.sass';


interface AnimatedProps {
  animation: 'fold' | 'unfold',
  digit: string,
}

const AnimatedCard = ({ animation, digit }: AnimatedProps) => {
  return (
    <div className={`flipCard ${animation}`}>
      <span>{digit}</span>
    </div>
  );
};


interface StaticProps {
  position: 'upperCard' | 'lowerCard',
  digit: string,
}


const StaticCard = ({ position, digit }: StaticProps) => {
  return (
    <div className={position}>
      <span>{digit}</span>
    </div>
  );
};


interface FlipUnitProps {
  digit: number,
  unit: 'days' | 'hours' | 'minutes' | 'seconds',
  unit_name: string
}


const FlipUnitContainer = ({ digit, unit, unit_name }: FlipUnitProps) => {

  const [shuffle, setShuffle] = useState(true);

  useEffect(() => {
    setShuffle(val => !val)
  }, [digit])

  // assign digit values
  let currentDigit = `${digit}`;
  const prevDig = digit + 1;
  let previousDigit = `${digit + 1}`;

  // to prevent a negative value
  const prevlim = {
    hours: 24,
    minutes: 60,
    seconds: 60,
    days: 100,
  }[unit]
  if (prevDig == prevlim) {
    previousDigit = "00";
  }

  // add zero
  if (digit < 10) {
    currentDigit = `0${currentDigit}`;
  }
  if (prevDig < 10) {
    previousDigit = `0${previousDigit}`;
  }

  // shuffle digits
  const digit1 = shuffle
    ? previousDigit
    : currentDigit;
  const digit2 = !shuffle
    ? previousDigit
    : currentDigit;

  // shuffle animations
  const animation1 = shuffle
    ? 'fold'
    : 'unfold';
  const animation2 = !shuffle
    ? 'fold'
    : 'unfold';

  return (
    <div>
      <h5 className="text-center">{unit_name}</h5>
      <div className={'flipUnitContainer'}>
        <StaticCard
          position={'upperCard'}
          digit={currentDigit}
        />
        <StaticCard
          position={'lowerCard'}
          digit={previousDigit}
        />
        <AnimatedCard
          digit={digit1}
          animation={animation1}
        />
        <AnimatedCard
          digit={digit2}
          animation={animation2}
        />
      </div>
    </div>
  );
};



interface IProps {
  id: string,
  fine: Date
}


const FlipClock = (props: IProps) => {

  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setTime(new Date()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const totalSeconds = Math.floor((props.fine.getTime() - time.getTime()) / 1000);
  const days = Math.floor(totalSeconds / 86400);
  const hours = Math.floor((totalSeconds % 86400) / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;


  return (
    <div className={'flipClock'}>
      {days > 0 &&
        <FlipUnitContainer
          unit={'days'}
          unit_name="Giorni"
          digit={days}
        />
      }
      <FlipUnitContainer
        unit={'hours'}
        unit_name="Ore"
        digit={hours}
      />
      <FlipUnitContainer
        unit={'minutes'}
        unit_name="Minuti"
        digit={minutes}
      />
      <FlipUnitContainer
        unit={'seconds'}
        unit_name="Secondi"
        digit={seconds}
      />
    </div>
  );
}


export default FlipClock;
