import { cloneDeep } from 'lodash';

import { SET_PUNTEGGIO, SET_TUTTI_PUNTEGGI } from '../actions/action-types';
import { PunteggioAction, TuttiPunteggiAction } from '../actions';
import { SquadraPunteggiStoria, PunteggiSessione } from '../api/types';
import { semplificato } from '../globals'

export const nuovo_punteggio_vuoto: SquadraPunteggiStoria = {
  states: [],
  classifica_tempo: {
    tempi: [],
    punteggi: {}
  }
}

export function punteggiReducer(state: PunteggiSessione = {},
  action: PunteggioAction | TuttiPunteggiAction) {
  if (action.type == SET_PUNTEGGIO) {
    // Se ci sono poche squadre, tieni in memoria tutta la storia
    const precedenti = state[action.sessione];
    let nuovo;

    const reduced = semplificato(action.punteggio.classifica.length);

    // Inizializzazione punteggio
    if (precedenti) {
      if (precedenti.states[0].ora ==
        action.punteggio.ora) {
        return precedenti;
      }
      nuovo = {
        ...precedenti,
        states: reduced ? [action.punteggio] : [action.punteggio, ...precedenti.states]
      }
    } else {
      nuovo = cloneDeep(nuovo_punteggio_vuoto);
      nuovo.states = [action.punteggio];
      for (const ogg of action.punteggio.classifica) {
        nuovo.classifica_tempo.punteggi[ogg.nome] = [];
      }
    }

    // Parte temporale
    if (!reduced) {
      nuovo.classifica_tempo.tempi.unshift(action.punteggio.ora);
      for (const ogg of action.punteggio.classifica) {
        nuovo.classifica_tempo.punteggi[ogg.nome].unshift(ogg.totale);
      }
    }
    return {
      ...state,
      [action.sessione]: nuovo
    };
  } else if (action.type == SET_TUTTI_PUNTEGGI) {
    let nuovo = cloneDeep(nuovo_punteggio_vuoto);
    if (action.punteggi.length == 0) {
      return nuovo;
    }
    // Inizializza i nomi delle squadre
    for (const ogg of action.punteggi[0].classifica) {
      nuovo.classifica_tempo.punteggi[ogg.nome] = [];
    }
    for (const pti of action.punteggi) {
      const cl = pti.classifica;
      nuovo.states.push(pti);
      for (const ogg of cl) {
        if (!nuovo.classifica_tempo.punteggi[ogg.nome]) {
          nuovo.classifica_tempo.punteggi[ogg.nome] = [];
        }
        nuovo.classifica_tempo.punteggi[ogg.nome].push(ogg.totale);
      }
      nuovo.classifica_tempo.tempi.push(pti.ora);
    }
    const ret = Object.assign({}, state);
    ret[action.sessione] = nuovo;
    return ret;
  } else {
    return state;
  }
}
