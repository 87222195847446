import React from 'react'
import { usePunteggiMarkov } from '../reducers';
import Plot from 'react-plotly.js';
import LoadingCircle from './LoadingCircle';


const download = (file: string, text: string) => {

  // creating an invisible element

  const element = document.createElement('a');
  element.setAttribute('href',
    'data:text/plain;charset=utf-8,'
    + encodeURIComponent(text));
  element.setAttribute('download', file);
  document.body.appendChild(element);
  element.click();

  document.body.removeChild(element);
}


const Latency = (props: { sessione: number }) => {
  const punteggi = usePunteggiMarkov(props.sessione);
  const latencies = punteggi?.eventi.map(ev => (ev.date_received.getTime() - ev.date.getTime()) / 1000)
  if (!latencies) {
    return <LoadingCircle />;
  }
  const filename = `sessione_${props.sessione}_eventi.json`;

  return (
    <>
      <Plot
        data={[{
          x: latencies,
          type: "histogram",
        }]}
        layout={{
          width: 1600, height: 600,
          title: "Latenza arrivo risposte",
          margin: { l: 200 },
          xaxis: { title: "Latenza (s)", },
          yaxis: { title: "Occorrenze", },
        }}
      />
      <button
        className="btn btn-info" type="button"
        onClick={() => download(filename, JSON.stringify(punteggi?.eventi))}
      >
        Scarica dettagli
      </button>
    </>
  )
}


export default Latency;
