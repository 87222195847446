import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/types';

import { sentry_config } from '../globals';
import store from '../store';



export function sentry_log(error: Error, error_level = Severity.Error, extra_scope?: any) {
  console.error(error)
  if (sentry_config.use_sentry) {
    const state = store.getState()
    const username = state.login.logged_in ?
      state.login.user.username :
      "Anonymous";
    Sentry.configureScope((scope) => {
      scope.setUser({
        "username": username
      });
      scope.setLevel(error_level);
    });
    Sentry.captureException(error);
  }
}
