import React from 'react';

import { ProblemaTabella } from '../api/types';

import './CasellaPunteggio.scss';

interface IProps {
  dettagli: ProblemaTabella
}

const CasellaPunteggio = (props: IProps) => {

  let classname: string = "";
  let punti: number;
  let jolly_icon;
  if (props.dettagli.risolto) {
    classname = "table-success";
  } else {
    if (props.dettagli.punti != 0) {
      classname = "table-danger";
    }
  }
  if (props.dettagli.nuovo) {
    classname += " table-new";
  }
  if (props.dettagli.jolly) {
    jolly_icon = (
      <div className="badge badge-pill badge-yellow">
        <i className="fa fa-angle-double-up fa-xs" />
      </div>
    );
  }
  if (props.dettagli.punti != 0 || props.dettagli.risolto) {
    punti = props.dettagli.punti;
  }
  return (
    <td className={classname}>
      <div className="d-flex">
        {punti}
        <div className="box jolly-overlay d-flex justify-content-end">
          {jolly_icon}
        </div>
      </div>
    </td>
  );
}

export default CasellaPunteggio;
