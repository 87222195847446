import React, { useEffect, useRef, useState } from 'react'

import { nice_date_seconds } from '../utils'
import { get_time } from '../api'
import { Evento } from '../api/types'

interface IProps {
  evento?: Evento
}


const DisplayOrario = (props: IProps) => {
  const refresh = useRef(null);
  const [now, setNow] = useState(new Date());
  const [errato, setErrato] = useState(false);
  const [difference, setDifference] = useState(0);

  useEffect(() => {
    get_time().then(ans => {
      const server_now = new Date(ans);
      const browser_now = new Date();
      setNow(server_now);

      // Se l'orologio del browser non è sincronizzato (10s) con il server, spawna un errore.
      const difference = (server_now.getTime() - browser_now.getTime()) / 1000;
      if (Math.abs(difference) > 10) {
        setErrato(true);
        setDifference(difference);
      }
    });

    const refresh_date = () => {
      setNow(val => {
        val.setSeconds(val.getSeconds() + 1);
        const newval = new Date(val);
        return newval;
      })
    }

    refresh.current = setInterval(refresh_date, 1000);

    return () => {
      clearInterval(refresh.current);
    }
  }, [])

  return (
    <>
      <h4 className="text-center">Orario attuale: {nice_date_seconds(now)}</h4>
      {now.getTime() > (new Date(props.evento?.fine)).getTime() &&
        <h3 className="text-center text-warning">L'evento è concluso!</h3>
      }
      {errato &&
        <>
          <h4 className="text-center text-warning">Attenzione! L'orario di questo computer non è sincronizzato con l'orario del server.</h4>
          <h4 className="text-center text-warning">Questo computer è {difference.toFixed(0)} secondi in {difference > 0 ? "ritardo" : "anticipo"}</h4>
        </>
      }
    </>
  )
}

export default DisplayOrario;
