import { cloneDeep } from 'lodash';

import {
  ClientError, InternalServerError, NetworkError,
  PermissionDenied, NotFound, APIError, TooManyRequests, BadRequest
} from './exceptions';

export interface plain_object {
  [name: string]: string | number | number[] | boolean | Blob | plain_object | plain_object[] | FileList
}


export interface WithId {
  id: number | string
}

export interface Displayable extends WithId {
  display: string,
}

interface HeadersInit {
  "X-CSRFToken"?: string
};

export interface PaginatedJson<T> {
  next: string | null,
  previous: string | null,
  results: T[],
  count: number
}

interface ObjectWithPage {
  "page": number | null
}




const http_params = {
  credentials: "same-origin",
  headers: {
    "Accept": "application/json",
    "Content-Type": "application/json"
  },
  method: ""
}

let http_get_params = Object.assign({}, http_params);
http_get_params.method = "GET"

let http_post_params = Object.assign({}, http_params);
http_post_params.method = "POST"

let http_put_params = Object.assign({}, http_params);
http_put_params.method = "PUT"

let http_patch_params = Object.assign({}, http_params);
http_patch_params.method = "PATCH"

let http_delete_params = Object.assign({}, http_params);
http_delete_params.method = "DELETE"

export {
  http_get_params, http_post_params, http_delete_params,
  http_put_params, http_patch_params
};

const non_safe_methods = new Array(
  "POST", "PUT", "PATCH", "DELETE",
);


/**
 * Questa funzione trasforma un plain object in un oggetto per query get.
 * {'cusu': 'mano', 'vill': 'ano'} => "?cusu=mano&vill=ano"
 */
export function get_url_from_params(params: plain_object): string {
  let ret = "?";
  for (let idx in params) {
    let cont = params[idx]
    if (cont instanceof Array) {
      cont = JSON.stringify(cont)
    }

    ret += idx + "=" + cont + "&";
  }
  return ret.slice(0, ret.length - 1);
}


/**
 * Questa funzione fa l'inverso di get_url_from_params
 */
export const get_params_to_obj = (searchpar: string) => {
  const search = searchpar.substring(1);
  const jsonstring = '{"' + decodeURI(search).replace(/"/g, '\\"')
    .replace(/&/g, '","').replace(/=/g, '":"') + '"}'
  return JSON.parse(jsonstring)
}


// Bisognerebbe usare instanceof ma non so perché non va
// https://stackoverflow.com/questions/25677681/javascript-file-is-instance-of-file-but-instanceof-file-is-false
function check_if_file(obj: any): boolean {
  if (Object.prototype.toString.call(obj) === '[object File]') {
    return true;
  }
  return false;
}


function check_if_filelist(obj: any): boolean {
  if (Object.prototype.toString.call(obj) === '[object FileList]') {
    return true;
  }
  return false;
}


function check_files(obj: plain_object): boolean {
  let value;
  Object.keys(obj).some(function(k) {
    if (obj[k] && typeof obj[k] === 'object') {
      if (check_if_file(obj[k])) {
        value = true;
        return true;
      }
      value = check_files(obj[k] as plain_object);
      return value !== undefined;
    }
  });
  return value;
}


/**
 * Fai una generica richiesta HTTP.
 * @param url l'url senza i parametri GET, ovvero senza ?cusu=mano
 * @param http_params Le cose da mettere nel pacchetto HTTP tranne il body
 * @param formData le cose da infilare nel body, tipo i form compilati
 * @param get_query_params I parametri HTTP GET come plain_object
 */
export async function make_request<T>(
  url: string, http_params: any, formData?: plain_object,
  get_query_params?: plain_object, add_loading_action?: () => void,
  del_loading_action?: () => void): Promise<T> {
  let real_params = cloneDeep(http_params);
  // Calcola url reale
  const real_url = url + get_url_from_params(get_query_params);
  if (non_safe_methods.includes(real_params.method)) {
    if (!formData) {
      formData = {};
    }
    let csrf = get_csrf_token();
    real_params["body"] = JSON.stringify(formData);
    real_params.headers["X-CSRFToken"] = csrf;
    if (check_files(formData)) {
      delete real_params.headers["Content-Type"];
      const data = new FormData();
      for (const [key, value] of Object.entries(formData)) {
        if (check_if_file(value)) {
          const vv = value as File
          data.append(key, vv, vv.name);
        } else if (check_if_filelist(value)) {
          const vv = value as FileList
          for (let i = 0; i < vv.length; i++) {
            data.append(key, vv[i] as Blob, vv[i].name);
          }
        } else if (typeof value === 'object') {
          return new Promise((_, reject) => {
            reject(new Error(
              "Non si supporta ancora nested post con file."));
          });
        } else {
          data.append(key, value as string);
        }
      }
      real_params["body"] = data;
    }
    console.log("Low level, submitting real_params", real_params, "formData", formData);
  }
  if (add_loading_action) { add_loading_action(); }
  return new Promise(function(resolve, reject) {
    fetch(real_url, real_params as any)
      .catch(error => reject(new NetworkError(error)))
      .then(async (response: Response) => {
        let errore = true;
        let error_class: typeof APIError = null;
        const status = response.status;
        if (status == 403) {
          error_class = PermissionDenied;
        } else if (status == 404) {
          error_class = NotFound;
        } else if (status == 429) {
          error_class = TooManyRequests;
        } else if (status == 400) {
          error_class = BadRequest;
        } else if (status > 399 && status < 500) {
          error_class = ClientError;
        } else if (status > 499 && status < 600) {
          error_class = InternalServerError;
        } else {
          errore = false;
        }
        return response.json().then((json: any) => {
          if (errore) {
            reject(new error_class(json));
          } else {
            resolve(json);
          }
        }).catch((error: any) => {
          // Django rest fornisce una rispsota vuota per
          // le richieste DELETE. Se fallisce la formattazione in
          // json è perché è vuoto.
          if (status === 204) {
            resolve(response as any);
          } else if (status > 499 && status < 600) {
            // django non fornisce dettagli quando c'è un internal server error,
            // per cui è opportuno evitare di bestemmiare se il json non è un json
            const messaggio = {
              detail: "Internal server error"
            };
            reject(new error_class(messaggio));
          } else {
            reject(error);
          }
        });
      }).finally(() => {
        if (del_loading_action) { del_loading_action(); }
      });
  });
}



export function get_csrf_token(): string {
  let cookie = document.cookie;
  let cookiearray = cookie.split(';');
  let appo = cookiearray.map((value) => {
    let split = value.split('=');
    let name = split[0];
    const match = name.match(/csrftoken/g);
    if (match && match.length > 0) {
      return split[1];
    }
  }).filter(item => item !== undefined)[0];
  return appo;
}


export function get_sessionid(): string {
  let cookie = document.cookie;
  let cookiearray = cookie.split(';');
  let appo = cookiearray.map((value) => {
    let split = value.split('=');
    let name = split[0];
    const match = name.match(/sessionid/g);
    if (match && match.length > 0) {
      return split[1];
    }
  }).filter(item => item !== undefined)[0];
  return appo;
}
