import React, { useState, Component } from 'react';

import { RigaPunteggi } from '../components';
import { SquadraTabella, SquadraInSessione, Problema } from '../api/types';
import { sortClassifica } from '../api';

import './TabellaPunteggi.scss'

interface IProps {
  punteggi: number[],
  nomi: { [squadra: string]: string; },
  dettagli_problemi: Problema[],
  classifica: SquadraTabella[]
  squadre: SquadraInSessione[],
  scuole_id: number[],
}


type Fields = "p" | "n" | "prob"


const TabellaPunteggi = (props: IProps) => {
  const [sortDir, setSortDir] = useState(1 as 1 | -1);
  const [sortField, setSortField] = useState("p" as Fields)
  const [sortProb, setSortProb] = useState(0)
  const [mostraScuole, setMostraScuole] = useState(false);

  const sortFactory = (val: Fields, idx?: number) => {
    if (val != "prob") {
      return () => {
        setSortField(val);
        setSortDir(p => (-1 * p) as -1 | -1)
      }
    }
    return () => {
      setSortField(val);
      setSortDir(p => (-1 * p) as -1 | -1)
      setSortProb(idx)
    }
  }

  const classifica = sortClassifica([...props.classifica], sortDir, sortField, sortProb);
  var problema_completo = [];
  for (let i = 0; i < props.dettagli_problemi.length; i++) {
    let cusu: any = Object.assign({}, props.dettagli_problemi[i]);
    cusu.punteggio = (props.punteggi) ? props.punteggi[i] : 0;
    problema_completo.push(cusu);
  }
  return (
    <>
      <div className="table-responsive" id="tabella-punteggi">
        <table className="table table-bordered
            table-hover text-nowrap table-responsive-lg">
          <thead className="thead-dark">
            <tr>
              <th rowSpan={2} key="1" onClick={sortFactory("p")}>
                <i className="fa fa-sort" /> Pos
              </th>
              <th rowSpan={2} key="2" onClick={sortFactory("n")}>
                <i className="fa fa-sort" /> Squadra
                <button className="btn btn-info ml-3 " style={{ padding: '0.0rem 0.75rem' }} type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setMostraScuole(val => !val)
                  }}
                >
                  {mostraScuole && "Nascondi scuole"}
                  {!mostraScuole && "Mostra scuole"}
                </button>
              </th>
              <th rowSpan={2} key="0">
                Punti
              </th>
              {problema_completo.map(
                (p, idx) => <th onClick={sortFactory("prob", idx)} key={idx + 3}>
                  <i className="fa fa-sort" /> {p.nome}
                </th>
              )}
            </tr>
            <tr>
              {problema_completo.map(
                (p, idx) => <th key={idx + 3}>
                  {p.punteggio}
                </th>)}
            </tr>
          </thead>
          <tbody>
            {classifica.map(
              (squadra: SquadraTabella) => {
                const evidenzia = props.scuole_id.includes(props.squadre.find(sq => sq.id == squadra.id)?.scuola.id)

                return (
                  <RigaPunteggi
                    key={squadra.nome} squadra={squadra} nome={props.nomi[squadra.nome]} posizione={squadra.posizione}
                    evidenzia={evidenzia} dettagli_squadra={props.squadre.find(sq => sq.id == squadra.id)}
                    mostra_scuola={mostraScuole}
                  />
                )
              })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default TabellaPunteggi
