import React, { useEffect, useState } from 'react';
import { sponsors } from '../globals'



const Sponsor = () => {
  const [horiz, setHoriz] = useState(window.innerWidth > window.innerHeight);

  useEffect(() => {
    document.title = "Sponsors - GaS";
  }, [])

  useEffect(() => {
    const handleResize = () => {
      const horiz2 = window.innerWidth > window.innerHeight;
      if (horiz != horiz2) {
        setHoriz(horiz2)
      }
    }
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [horiz])


  const size = horiz ? '40%' : '80%';

  return (
    <div className="container">
      <h1 className="page-header">Sponsors</h1>
      <div className="d-flex flex-column">
        {sponsors.map((sponsor, idx) =>
          <div className="d-flex justify-content-center pb-5" key={idx}>
            <div className="d-flex flex-column">
              <div className="d-flex flex-row justify-content-center">
                <div className="bannerino flex-grow-1 py-3" style={{ maxWidth: size }}>
                  <a href={sponsor.website}>
                    <img
                      src={sponsor.logo_url}
                      className="img-thumbnail"
                      alt={sponsor.name}
                      style={{ borderStyle: 'none', maxHeight: '200px' }}
                    />
                  </a>
                </div>
              </div>
              <p>{sponsor.description}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Sponsor;
