import { useSelector } from 'react-redux'
import { useEffect } from 'react'


import {
  soft_refresh_cache, WithId, get_cached_item, get_cached_detail_item
} from './api_utils';
import { AppState, reducers_map, store } from './imported_stuff';
import { FiltroItem } from './redux-connecter'


type ownProps = any;
type RefreshableItem = FiltroItem<any>


const mapStateToPropsFactory = (refreshableItem: RefreshableItem, ownProps: ownProps) => {
  return (state: AppState) => {
    const item = refreshableItem
    const oggetto = item.oggetto || reducers_map[item.name];
    const cache = oggetto.cache(state);
    if (!cache.filter) {
      if (item.detail && item.pk) {
        const pk = item.pk(state, ownProps);
        return get_cached_detail_item(state, oggetto, pk);
      } else {
        if (item.extra_filter) {
          const filter_function = (item2: any) => item.extra_filter(item2, state, ownProps);
          return get_cached_item(state, oggetto).filter(filter_function);
        } else {
          return get_cached_item(state, oggetto);
        }
      }
    } else {
      if (item.filtro) {
        return get_cached_item(state, oggetto, item.filtro(ownProps));
      } else {
        return cache;
      }
    }
  }
}

const refresh_all_factory = (refreshableItem: RefreshableItem, ownProps: ownProps) => {
  return () => {
    const item = refreshableItem;
    const oggetto = item.oggetto || reducers_map[item.name];
    const cache = oggetto.cache(store.getState());
    const filtro = !!cache.filter ? item.filtro(ownProps) : null;
    soft_refresh_cache(oggetto, filtro);
  }
}


export const useSelectorRefresh: (oP: ownProps, rI: RefreshableItem | string, eF?: any) => any
  = (ownProps, item, equalityFunction) => {

    const refreshableItem = (typeof item === "string") ? { name: item } : item;

    const refresh_all = refresh_all_factory(refreshableItem, ownProps);
    const mapStateToProps = mapStateToPropsFactory(refreshableItem, ownProps);
    useEffect(() => { refresh_all() }, []);

    return useSelector(mapStateToProps, equalityFunction)
  }
