import React, { Component } from 'react';
import { connect } from "react-redux";

import Plot from 'react-plotly.js';
import strftime from 'strftime';

import { AppState } from '../reducers';
import { PunteggiSessione, Evento } from '../api/types';
import LoadingCircle from '../components/LoadingCircle'
import { rgb_string_from_name } from '../utils'

interface IProps {
  pk: number,
  punteggi: PunteggiSessione,
  nomi: { [squadra: string] : string; },
  evento: Evento
}


const mapStateToProps = (state: AppState) => {
  return { punteggi: state.punteggi }
}


class GraficoPunteggi extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    if (this.props.evento) {
      const adesso = new Date();
      const inizio = new Date(this.props.evento.inizio);
      if (inizio > adesso) {
        return <p>L'evento non è ancora iniziato.</p>;
      }
    }
    const pp = this.props.punteggi;
    if (Object.entries(pp).length === 0 && pp.constructor === Object) {
      return (
        <LoadingCircle />
      );
    }
    let p;
    try {
      p = pp[this.props.pk].classifica_tempo;
    }
    catch (err) {
      return (
        <LoadingCircle />
      );
    }
    let plot_data = [];
    const time_format = "%Y-%m-%d %H:%M:%S";
    for (let squadra in p.punteggi) {
      if (p.punteggi.hasOwnProperty(squadra)) {
        let tempi_filtrati = [];
        let punteggi_filtrati = []
        let nome = this.props.nomi[squadra];
        const pti = p.punteggi[squadra];
        tempi_filtrati.push(strftime(time_format, p.tempi[0]));
        punteggi_filtrati.push(pti[0]);
        for (let i = 1; i < pti.length - 1; i++) {
          if (pti[i] == pti[i - 1]) {
            continue;
          }
          tempi_filtrati.push(strftime(time_format, p.tempi[i]));
          punteggi_filtrati.push(pti[i]);
        }
        tempi_filtrati.push(strftime(time_format, p.tempi[p.tempi.length - 1]));
        punteggi_filtrati.push(pti[pti.length - 1]);
        const color = rgb_string_from_name(squadra);
        plot_data.push({
          x: tempi_filtrati,
          y: punteggi_filtrati,
          type: "scatter" as "scatter", // Nessun commento su questo casting esplicito.
          mode: 'lines+markers' as "lines+markers", // Mannaggia a typescript.
          name: nome,
          marker: {
            color: color,
            size: 8,
          },
          line: {
            color: color,
            width: 2,
          },
        });
      }
    }
    return (
      <Plot
        data={plot_data}
        layout={{
          width: 1440, height: 600, title: "Punteggio",
        }}
      />
    );
  }
}

export default connect(mapStateToProps)(GraficoPunteggi);
