import React, { Component } from 'react';

interface IProps {
  error?: any
  hidejson?: any
}


export default class ErrorBlockGeneric extends Component<IProps> {
  render() {
    if (this.props.error) {
      return (
        <div className="alert alert-danger" role="alert">
          <h4 className="alert alert-heading">Errore</h4>
          {this.props.hidejson ? null : (JSON.stringify(this.props.error))}
        </div>
      )
    } else {
      return null;
    }
  }
}
