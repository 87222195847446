import { SET_PUNTEGGI_SQUADRE_PROBLEMI, ADD_PUNTEGGI_EVENTO, SET_MARKOV_STATO, RESET_EVENTI, CHANGE_PARAMETERS } from './action-types'
import { SquadraInSessione, EventoPunteggiEnriched, EventoPunteggi, PunteggiStatoBackend, Evento, TunableParametersForm as TunableParameters } from '../api/types'

export interface PunteggiDistributedAction {
  type: string,
  sessione: number,
  squadre?: SquadraInSessione[],
  num_problemi: number,
  evento?: EventoPunteggiEnriched,
  evento_gas?: Evento,
  stato?: PunteggiStatoBackend,
  newparameters?: {

  }
}


export function set_punteggi_squadre_action(sessione: number, evento: Evento, squadre: SquadraInSessione[], num_problemi: number) {
  return {
    type: SET_PUNTEGGI_SQUADRE_PROBLEMI,
    evento_gas: evento,
    squadre: squadre,
    sessione: sessione,
    num_problemi: num_problemi,
  }
}


export function add_punteggi_evento_action(sessione: number, evento: EventoPunteggi, mock_received?: Date) {
  const received = !!mock_received ? mock_received : new Date();
  return {
    type: ADD_PUNTEGGI_EVENTO,
    sessione: sessione,
    evento: {
      ...evento,
      date_received: received,
    }
  }
}


export function set_markov_stato(sessione: number, evento: Evento, stato: PunteggiStatoBackend) {
  return {
    type: SET_MARKOV_STATO,
    sessione: sessione,
    stato: stato,
    evento_gas: evento,
  }
}


export function reset_eventi(sessione: number) {
  return {
    type: RESET_EVENTI,
    sessione: sessione,
  }
}


export function change_parameters(sessione: number, parameters: TunableParameters) {
  return {
    type: CHANGE_PARAMETERS,
    sessione: sessione,
    newparameters: parameters
  }
}
