import {
  Studente, Sessione, Squadra,
  City, Evento, Country, Regione, Scuola,
  MessaggioImportante, Problema
} from '../api/types';

import {
  Esportone, StructuredEsportone, WithId, useSelectorRefresh, useFilteredNoRefresh, useFiltered
} from 'django-rest-react';


export enum Reverse {
  Persona = "persone",
  Regione = "regioni",
  Evento = "eventi",
  Country = "countries",
  City = "cities",
  Scuola = "scuola",
  Sessione = "sessioni",
  Squadra = "tue_squadre",
  MessaggioImportante = "messaggi_importanti",
  Problema = "problemi",
}

type AppState = any;

export const persone_all = new Esportone<Studente>(
  "PERSONA", () => "/api/gas/extended-user/", (state: AppState) => state[Reverse.Persona]);
export const regione_all = new Esportone<Regione>(
  "REGIONE", () => "/api/ext-user/region/", (state: AppState) => state[Reverse.Regione]);
export const evento_all = new Esportone<Evento>(
  "EVENTO", () => "/api/gas/evento/?tipologia=o", (state: AppState) => state[Reverse.Evento]);
export const country_all = new Esportone<Country>(
  "COUNTRY", () => "/api/ext-user/country/", (state: AppState) => state[Reverse.Country]);
export const city_all = new StructuredEsportone<City>(
  "CITY", () => "/api/ext-user/city/", (state: AppState) => state[Reverse.City]);
export const scuola_all = new Esportone<Scuola>(
  "SCUOLA", () => "/api/ext-user/scuola/?mie=y", (state: AppState) => state[Reverse.Scuola]);
export const sessione_all = new Esportone<Sessione>(
  "SESSIONE", () => "/api/gas/sessione/", (state: AppState) => state[Reverse.Sessione]);
export const squadra_all = new StructuredEsportone<Squadra>(
  "SQUADRA", () => "/api/gas/squadra/", (state: AppState) => state[Reverse.Squadra]);
export const messaggi_importanti_all = new StructuredEsportone<MessaggioImportante>(
  "MESSAGGIO_IMPORTANTE", () => "/api/gas/messaggi-importanti/", (state: AppState) => state[Reverse.MessaggioImportante])
export const problemi_all = new StructuredEsportone<Problema>(
  "PROBLEMA", () => "/api/gas/problema/", (state: any) => state[Reverse.Problema]);



interface ReverseMap {
  [key: string]: Esportone<WithId> | StructuredEsportone<WithId>
}

export const reducers_map: ReverseMap = {
  [Reverse.Persona]: persone_all,
  [Reverse.Regione]: regione_all,
  [Reverse.Evento]: evento_all,
  [Reverse.Country]: country_all,
  [Reverse.City]: city_all,
  [Reverse.Scuola]: scuola_all,
  [Reverse.Sessione]: sessione_all,
  [Reverse.Squadra]: squadra_all,
  // No messaggio importante qui, abbiamo patchato il reducer
  // e non va bene quello di default della cache
  [Reverse.Problema]: problemi_all,
}


export const usePersona: (props: any) => Studente[] = props => useSelectorRefresh(props, Reverse.Persona);
export const useRegione: (props: any) => Regione[] = props => useSelectorRefresh(props, Reverse.Regione);
export const useEvento: (props: any) => Evento[] = props => useSelectorRefresh(props, Reverse.Evento);
export const useCountry: (props: any) => Country[] = props => useSelectorRefresh(props, Reverse.Country);
export const useCity: (props: any) => City[] = props => useSelectorRefresh(props, Reverse.City);
export const useScuola: (props: any) => Scuola[] = props => useSelectorRefresh(props, Reverse.Scuola);
export const useSessione: (props: any) => Sessione[] = props => useSelectorRefresh(props, Reverse.Sessione);
export const useSquadra: (session_pk?: number) => Squadra[] = session_pk => session_pk ? useFiltered<Squadra>(Reverse.Squadra, [{name: 'sessione', value: session_pk.toString() }]) : useFilteredNoRefresh<Squadra>(Reverse.Problema, []);
export const useProblema: (event_pk?: number) => Problema[] = event_pk => event_pk ? useFilteredNoRefresh<Problema>(Reverse.Problema, [{ name: 'evento', value: event_pk.toString() }]) : useFilteredNoRefresh<Problema>(Reverse.Problema, []);
export const useMessaggioImportante: (event_pk?: number) => MessaggioImportante[] = event_pk => event_pk ? useFilteredNoRefresh<MessaggioImportante>(Reverse.MessaggioImportante, [{ name: 'evento', value: event_pk.toString() }]) : useFilteredNoRefresh<MessaggioImportante>(Reverse.MessaggioImportante, []);

let reducers_veri: ReverseMap = {};
Object.keys(reducers_map).forEach((key: Reverse) => {
  reducers_veri[key] = reducers_map[key].objectReducer.bind(reducers_map[key]);
});
export { reducers_veri };
