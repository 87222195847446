import { AppState } from '../reducers';


export interface LoginStateProps {
  login: AppState["login"],
}

export const mapStateToProps: (state: AppState) => LoginStateProps =
  (state) => {
    return {
      login: state.login,
    };
  }
