import { createStore, applyMiddleware } from 'redux';
import rootReducer from '../reducers/index';
import { SHOW_STORE_DEBUG } from '../globals';


const logger = (store: any) => (next: any) => (action: any) => {
  console.log('dispatching', action)
  let result = next(action)
  console.log('next state', store.getState())
  return result
}

let middleware = [] as any[];
if (SHOW_STORE_DEBUG) {
  middleware.push(logger);
}

const store = createStore(
  rootReducer,
  applyMiddleware(...middleware)
);

export default store;
