import React, { Component, ReactElement } from 'react'

interface DataTableProps {
  left: string | ReactElement,
  right: string | ReactElement
}

const DataTable = (props: DataTableProps) =>
  <dl className="row my-0">
    <dt className="col-sm-4 text-right">{props.left}</dt>
    <dd className="col-sm-8">{props.right}</dd>
  </dl>

export default DataTable
