
let reducers_map: any;
let store: any;
let DEBUG: boolean;
let connection_action: (val: string) => any;
let disconnection_action: (val: string) => any;


export const configure_drr = (red_map: any, sto: any, debug: boolean, con: (val: string) => any, discon: (val: string) => any) => {
  reducers_map = red_map;
  store = sto;
  DEBUG = debug;
  connection_action = con;
  disconnection_action = discon;
}
type AppState = any;

export { AppState, reducers_map, store, DEBUG, connection_action, disconnection_action };
