import React from 'react'
import { useLocation, useMatch } from 'react-router-dom'

import { get_params_to_object } from 'django-rest-react'


const LoginError = () => {
  const location = useLocation()
  const errmsg = get_params_to_object(location.search).error

  return (
    <div className="container">
      <h1 className="page-header">Login error</h1>
      <p>Errore: <code>{errmsg}</code>.</p>
    </div>
  )
}


export default LoginError
