import React from 'react';
import { Link } from "react-router-dom";


interface IProps {
  url?: string
  color: string
  text: string
  textTop?: string
  icon: string
  absolute?: boolean // true se il link è esterno al sito
  newpage?: boolean
}

const Tile = (props: IProps) => {
  const classes = "thumbnail tile tile-medium mx-3 my-3 bg-" + props.color;
  const fa = props.icon.includes("fab ") ? "fab" : "fa";
  const icon = `${fa} icon ${props.icon}`;
  const newpage = props.newpage ? { target: "_blank", rel: "noopener noreferrer" } : {}
  let badgeTop;
  if (props.textTop) {
    badgeTop = (<span className="badge-top">{props.textTop}</span>);
  }
  const content = (
    <div className={classes}>
      <span className="branding-bar">{props.text}</span>
      {badgeTop}
      <span><i className={icon}></i></span>
    </div>
  );
  if (!props.url) {
    return content
  }
  if (props.absolute) {
    return <a href={props.url} {...newpage}>
      {content}
    </a>
  }
  return (
    <Link to={props.url} {...newpage}>
      {content}
    </Link>
  );
}

export default Tile;
