import React from 'react';

import { FilePubblicazioneProgrammata } from '../api/types';

import { short_date_options } from '../globals';
import Tile from '../components/Tile';

interface IProps {
  file: FilePubblicazioneProgrammata
  didascalia: string
}


const FilePubblicazioneProgrammataComponent = (props: IProps) => {
  const { file, didascalia } = props;
  if (!file) {
    return (
      <Tile
        color="danger" text={`${didascalia}: non disponibile!`}
        icon="fa-times"
      />
    );
  }
  const pub = new Date(file.data);
  const adesso = new Date();
  const url = file.pubblico ? file.programmed_path_url : `/files/${file.id}/`;
  let block;

  if (adesso < pub) {
    let data_pub = pub.toLocaleDateString('it', short_date_options);
    block = (
      <Tile
        color="warning" text={didascalia} textTop={data_pub}
        icon="fa-file-pdf" url={url}
        absolute={true}
        newpage={true}
      />
    );
  } else {
    block = (
      <Tile
        url={url}
        color="success" text={didascalia}
        icon="fa-file-pdf"
        absolute={true}
        newpage={true}
      />
    );
  }

  return (
    <>
      {block}
    </>
  );
}

export default FilePubblicazioneProgrammataComponent
